<div class="container">
  <div class="row">
    <div class="col-12 mt-5">
      <h2 class="text-center">{{text?.name}}</h2>
    </div>

    <div class="col-12">
      <p [innerHTML] = "text?.text"></p>
    </div>
  </div>
</div>
