import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { QuestionsService } from 'src/app/services/questions.service';
import { TokenService } from 'src/app/services/token.service';
import { faPencilAlt, faTrash } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss']
})
export class CommentsComponent implements OnInit, OnChanges {
  @Input() id = '';

  public url = "https://picsum.photos/400";
  visible = true;
  message = ''
  formGroup: FormGroup;
  formEdit: FormGroup;
  questions: any[] = [];
  page = 1;
  pageSize = 1;
  collectionSize = 1;
  userId = '';
  selectedQuestion = null;
  trash = faTrash;
  pencil = faPencilAlt;

  constructor(private questionsService: QuestionsService, private tokenService: TokenService, private modalService: NgbModal) { }

  ngOnInit(): void {
    this.userId = this.tokenService?.currentUserValue()?._id || null;
    this.formGroup = new FormBuilder().group({
      message: new FormControl('', [Validators.required]),
    });

    this.formEdit = new FormBuilder().group({
      message: new FormControl('', [Validators.required]),
    });


  }

  ngOnChanges(changes: SimpleChanges): void {
    this.getMessages();
  }

  show() {
    if (!this.visible) {
      this.visible = !this.visible
    }
  }

  hide() {
    if (this.visible) {
      this.visible = !this.visible
    }
  }

  getMessages(page = 1) {
    if (!this.id) return;
    this.questionsService.getQuestions(this.id, page).subscribe((questions: any[]) => {
      this.questions = questions['docs'];
      this.page = questions['page'];
      this.collectionSize = questions['totalPages'];
    })
  }

  send(value) {
    this.questionsService.sendQuestion(this.id, value.message).subscribe(value => {
      this.getMessages(this.page);
      this.formGroup.reset();
    })
  }

  openModal(content, question) {
    this.selectedQuestion = question
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' });
    this.formEdit.patchValue({ message: question.message })
  }

  deleteMsg() {
    if (!this.selectedQuestion) return;
    this.questionsService.delete(this.selectedQuestion._id).subscribe(data => {
      this.modalService.dismissAll();
      this.getMessages(this.page)
    },
      err => {
        console.log("||||err|||", err);
      })
  }

  editMsg(value: any) {
    this.questionsService.put({ _id: this.selectedQuestion._id, message:value.message }).subscribe(data => {
      this.modalService.dismissAll();
      this.getMessages(this.page)
    },
      err => {
        console.log("|||err|||", err);
      })
  }

}
