import { Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ContactService } from '../services/contact.service';
import { DynamicFormModel } from '../shared/components/dynamicform/models/dynamicform.model';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  constructor(private contactService: ContactService, private router:Router) { }

  ngOnInit(): void {
  }

  public buttonsData: any = {
    parentClass: 'mt-4 mb-5',
    buttons: [
      {
        hasDisabled : true,
        cssContainer: "",
        cssInput: "btn btn-primary text-white",
        title: "send",
        callback: (values) => {
         this.send(values);
        }
      }
    ]
  }


  public formValue: DynamicFormModel[] = [
    {
      parentClass: 'row',
      inputs: [
        {
          classFormGroup: 'col-12',
          classInput: '',
          key: 'name',
          type: 'text',
          placeholder: 'user.first_name',
          formBuild: {
            default: "",
            validators: [Validators.required]
          }
        },
        {
          classFormGroup: 'col-12 col-md-6',
          classInput: '',
          key: 'email',
          type: 'email',
          placeholder: 'Email*',
          formBuild: {
            default: "",
            validators: [Validators.required]
          }
        },
        {
          classFormGroup: 'col-12 col-md-6',
          classInput: '',
          key: 'phone',
          type: 'text',
          placeholder: 'info.phone',
          formBuild: {
            default: "",
            validators: []
          }
        },
        {
          classFormGroup: 'col-12 col-md-6',
          classInput: '',
          key: 'user_type',
          type: 'select',
          options: [
            { value: "buyer", label: "product.buyer" },
            { value: "seller", label: "product.seller" },
          ],
          placeholder: 'User Type',
          formBuild: {
            default: "buyer",
            validators: [Validators.required]
          }
        },
        {
          classFormGroup: 'col-12 col-md-6',
          classInput: '',
          key: 'subject',
          type: 'text',
          placeholder: 'subject*',
          formBuild: {
            default: "",
            validators: [Validators.required]
          }
        },
        {
          classFormGroup: 'col-12',
          classInput: '',
          key: 'message',
          type: 'textarea',
          placeholder: 'message*',
          formBuild: {
            default: "",
            validators: [Validators.required]
          }
        },
        {
          classFormGroup: 'col-12',
          classInput: '',
          key: 'captcha',
          type: 'captcha',
          formBuild: {
            default: '',
            validators: [Validators.required]
          }
        }
      ]
    }
  ]

  send($event){
    this.contactService.create($event).subscribe(isOk=>{
      this.router.navigate(['/contact_success'])
    },
    isKo=>{
      console.log(isKo);
    })
  }

}
