import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { type } from 'os';
import { BehaviorSubject, Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { NotificationEventCodes } from 'src/app/services/models/notificatonData';

const ROUTES = {
  newOrder: `/my-panel/orders`,
  updateOrderStore: `/account/purchases`,
  updateOrderClient: `my-panel/orders`,
  chatStore: `/my-panel/messages`,
  chatUser: `/account/messages`,
  ticketNew: `/account/support`,
  ticketUpdate_store: "/my-panel/support",
  ticketUpdate_user: "/account/support",
}
@Injectable({
  providedIn: 'root'
})
export class ToastService {

  private notificationUpdate = new BehaviorSubject<any>(null);
  public notificationUpdate$: Observable<any> = this.notificationUpdate.asObservable();

  constructor(
    private toastr: ToastrService,
    private translateService: TranslateService,
    private router: Router) { }

  error(msg: string) {
    let toast = this.toastr.findDuplicate('', msg, false, false)
    if (!toast) this.toastr.error(msg, '', { positionClass: 'toast-bottom-right' });
  }

  ok(msg: string) {
    const translate = this.translateService.instant('save');
    let toast = this.toastr.findDuplicate('', translate, false, false)
    if (!toast) this.toastr.success(translate, '', { positionClass: 'toast-bottom-right' });
  }

  info(msg: string) {
    let toast = this.toastr.findDuplicate('', msg, false, false)
    if (!toast) this.toastr.info(msg, '', { positionClass: 'toast-bottom-right' });
  }

  notification(notification: { body: string, tag: string, title: string }, data) {
    let route = '';
    if (data.type === NotificationEventCodes.newOrder) route = ROUTES.newOrder
    if (data.type === NotificationEventCodes.updateOrder_by_store) route = ROUTES.updateOrderStore;
    if (data.type === NotificationEventCodes.updateOrder_by_user) route = ROUTES.updateOrderClient;
    if (data.type === NotificationEventCodes.chatStore) route = ROUTES.chatStore;
    if (data.type === NotificationEventCodes.chatUser) route = ROUTES.chatUser;
    if (data.type === NotificationEventCodes.ticketNew) route = ROUTES.ticketNew;
    if (data.type === NotificationEventCodes.ticketUpdate_store) route = ROUTES.ticketUpdate_store;
    if (data.type === NotificationEventCodes.ticketUpdate_user) route = ROUTES.ticketUpdate_user;

    if (this.router.url === route) {
      this.notificationUpdate.next(data);
      return;
    };

    this.toastr.info(notification.body, notification.title, { positionClass: 'toast-bottom-right' })
      .onTap.pipe(take(1)).subscribe(() => this.notificationHandler(route));
  }

  notificationHandler(route) {
    if (!route) return;
    this.router.navigate([route]);
  }
}
