import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { faBookmark, faUser } from '@fortawesome/free-regular-svg-icons';
import { faSearch, faMinusCircle, faStore } from '@fortawesome/free-solid-svg-icons';
import firebase from 'firebase/compat/app';
import { AuthService } from 'src/app/services/auth.service';
import { NotificationRecount } from 'src/app/services/models/notificationRecount';
import { PushNotificationService } from 'src/app/services/notifications/pushnotifications';
import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-logged-mbl',
  templateUrl: './logged-mbl.component.html',
  styleUrls: ['./logged-mbl.component.scss']
})
export class LoggedMblComponent implements OnInit {

  @Input() userName: string;
  @Input() img: string;
  @Input() recount: NotificationRecount;
  @Output() closeEmit = new EventEmitter<any>();

  faSearch = faSearch;
  faBookmark = faBookmark;
  iconCloseSession = faMinusCircle;
  iconStoreSession = faStore;
  iconProfile = faUser;

  constructor(
    private tokenService: TokenService,
    private router: Router,
    private pushNotificationService: PushNotificationService,
    private authService: AuthService) { }

  ngOnInit(): void {
  }

  logout() {

    this.tokenService.logout();
    this.router.navigate(['/home']);
   /* if (!firebase.messaging.isSupported()) {
      this.tokenService.logout();
      this.router.navigate(['/home']);
      return;
    }
    this.pushNotificationService.deleteToken().then(isRemoved => {
      this.pushNotificationService.getToken().then(deviceToken => {
        if (isRemoved) {
          this.authService.removeDeviceToken(deviceToken).subscribe(data => {
            this.tokenService.logout();
            this.router.navigate(['/home']);
          })
        }
      })
    })*/

  }

  store() {
    this.closeEmit.emit()
    var gotStore = this.tokenService.currentUserValue().gotStore;
    gotStore ? this.router.navigate(['/my-panel']) : this.router.navigate(['/my-store/connect']);

  }

  myAccount() {
    this.router.navigate(['/account']);
  }

}
