import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { CategoryService } from 'src/app/services/common/category.service';

@Component({
  selector: 'app-categoryselect',
  templateUrl: './categoryselect.component.html',
  styleUrls: ['./categoryselect.component.scss']
})
export class CategoryselectComponent implements OnInit {

  categories: any = [];
  tree = [];
  public second = null;
  public third = null;
  private firstSelect = null;
  private secondSelect = null;
  private selected = null;
  @Output() categoryEmit = new EventEmitter<string>();

  constructor(private categoryService: CategoryService) { }

  ngOnInit(): void {
    this.categoryService.getCategories().subscribe((data: any[]) => {
      this.categories = data;
    })
  }

  /*onChanges(_id) {
    this.tree = this.getParent(this.categories, _id, []);
    console.log(this.tree);
  }

  getParent(cat, _id, node) {
    cat.map((n) => {
      if (n._id == _id) {
        /*if(n.subcategories){
          console.log(n);
          n.subcategories.map(sub => {
            sub.subcategories = [];
          })
        }
        //n.subcategories = [];
        return node = [n];
      }
      if (n.subcategories) {
        return node = this.getParent(n.subcategories, _id, node);
      }
    });

    return node || [];
  }*/

  secondLevel(id:string){
    this.third = [];
    let category = this.categories.find(val => val._id == id);
    this.second = category ? category.subcategories : null;
    this.firstSelect = category;
    this.selected = this.firstSelect;
    this.secondSelect = null;
    this.categoryEmit.emit(this.selected ? this.selected._id : '');
  }

  thirdLevel(id:string, cat:any[]){
    let category = cat.find(val => val._id == id);
    this.third = category ? category.subcategories : null;
    this.secondSelect = category;
    this.selected = category ? category : this.firstSelect;
    this.categoryEmit.emit(this.selected._id);
  }

  setThirdSelect(cat_id:string, cat:any[]){
    let category = cat.find(val => val._id == cat_id);
    this.selected = category ? category: this.secondSelect ;
    this.categoryEmit.emit(this.selected._id);
  }
}
