import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { faSearch, faBookmark, faStore, faUser, faMinusCircle, faBell } from '@fortawesome/free-solid-svg-icons';
import { TokenService } from 'src/app/services/token.service';
import { Router } from '@angular/router';
import { PushNotificationService } from 'src/app/services/notifications/pushnotifications';
import { AuthService } from 'src/app/services/auth.service';
import firebase from 'firebase/compat/app';
import { NotificationRecount } from 'src/app/services/models/notificationRecount';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-logged',
  templateUrl: './logged.component.html',
  styleUrls: ['./logged.component.scss']
})
export class LoggedComponent implements OnInit {

  @Input() userName: string;
  @Input() img: string;
  @Input() recount: NotificationRecount;
  @ViewChild('btnMenu') btnMenu: ElementRef;

  faSearch = faSearch;
  faBookmark = faBookmark;
  iconCloseSession = faMinusCircle;
  iconStoreSession = faStore;
  iconProfile = faUser;
  notification = faBell;

  constructor(
    private tokenService: TokenService,
    private router: Router,
    private pushNotificationService: PushNotificationService,
    private authService: AuthService) { }

  ngOnInit(): void {
  }

  logout() {
    //if (!firebase.messaging.isSupported()) {
      this.tokenService.logout();
      this.router.navigate(['/home']);
   //   return;
   /* }
    this.pushNotificationService.deleteToken().subscribe(isRemoved => {
      this.pushNotificationService.getToken().subscribe(deviceToken => {
        if (isRemoved) {
          this.authService.removeDeviceToken(deviceToken).subscribe(data => {
            this.tokenService.logout();
            this.router.navigate(['/home']);
          })
        }
      })
    })*/

  }

  openMenu() {
    this.btnMenu.nativeElement.click();
  }

  store() {

    var gotStore = this.tokenService.currentUserValue().gotStore;
    gotStore ? this.router.navigate(['/my-panel']) : this.router.navigate(['/my-store/connect']);
  }

  myAccount() {
    this.router.navigate(['/account']);
  }

}
