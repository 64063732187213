<div class="container-fluid pt-5 mb-5">
	<div class="row text-center">

		<div class="col-12 col-md-4 col-lg-3 d-none d-md-block text-left">
			<h4>{{ 'categories' | translate }}</h4>
			<menu-categories [callback]="selectCategory" [categories]="categories" [level]='0'
				(clickCategory)="selectCategory($event)"></menu-categories>
		</div>

		<div class="col-12 col-md-4 col-lg-3 d-block d-md-none text-center" id="accordion">

			<div class="card">
				<div class="card-header" id="headingOne">
					<h5 class="mb-0" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false"
						aria-controls="collapseOne">
						{{ 'categories' | translate }}
					</h5>
				</div>

				<div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
					<menu-categories [callback]="selectCategory" [categories]="categories" [level]='0'
						(clickCategory)="selectCategory($event)"></menu-categories>
				</div>
			</div>
		</div>

		<div class="col-12 col-md-8 col-lg-9">
			<div class="row" *ngIf="products.length === 0">
				<div class="col-12">
					<div class="element">
						<h3 class="item">{{ 'no_products_cat' | translate }}</h3>
					</div>
				</div>
			</div>
			<div class="row" *ngIf="products.length > 0">
				<app-cardlist [data]="products"></app-cardlist>
			</div>

		</div>
	</div>
</div>