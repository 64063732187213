import { Injectable } from "@angular/core";
import { BaseService } from "./common/base.service";
import { LoadingService } from './loading.service';
import { HttpClient } from "@angular/common/http";
import { ErrorService } from "./common/error.service";
import { TokenService } from './token.service';
import { ToastService } from './common/toast.service';
import { Buy } from "../models/buy.model";

@Injectable({
  providedIn: 'root'
})
export class CartService extends BaseService {

  constructor(public http: HttpClient, public error: ErrorService, public token: TokenService, public toast: ToastService, public loadingService: LoadingService) {
    super(http, error, token, toast, loadingService);
    this.host = this.host + "cart";
  }

  getPayMethods() {
    const url = this.host + '/payMethod';
    return this.doGet(url, true);
  }

  checkout(data: Buy) {
    const url = this.host + '/checkout';
    return this.doPost(url, data, true);
  }

  getCheckouts(page:number = -1){
    const orderBy = JSON.stringify([{field:'createdAt', orderBy:"DESC"}])
    const url = this.host + '/checkout?page='+ page+'&order='+ orderBy;
    return this.doGet(url, true);
  }

  getCheckout(id: string) {
    const url = this.host + '/checkout/'+id;
    return this.doGet(url, true);
  }

  cancelOrder(id: string) {
    const url = this.host + '/checkout/cancel/' + id;
    return this.doDelete(url);
  }

  orderReceived(id: string) {
    const url = this.host + '/checkout/received/' + id;
    return this.doPut(url, {});
  }

}
