<div class="row" *ngIf="questions?.length === 0">
  <div class="col-12">
    <label>
      {{'no_questions' | translate}}
    </label>
  </div>
</div>


<div class="row">

  <div class="col-12 mb-3" *ngIf="visible && userId != null">

    <form [formGroup]="formGroup" (ngSubmit)="send(formGroup.value)">

      <textarea type="text" class="form-control" formControlName="message"
        placeholder="Make me a question about my product!"></textarea>

      <div class="form-group mt-3" data-toggle="buttons">
        <button [disabled]="!formGroup.valid" type="submit" class="btn btn-primary mr-2">Send question</button>
      </div>
    </form>
  </div>
</div>

<div class="row content" *ngFor="let q of questions">
  <div class="col-2">
    <div class="avatar" [style.background-image]="'url(' + q?.user.img || url + ')'"></div>
  </div>
  <div class="col-10">
    <div class="row mt-4">
      <div class="col-12 col-md-6 text-left">
        <h5 class="name">{{q?.user?.name}}</h5>
      </div>

      <div class="col-12 col-md-6 text-right">
        <h5 class="date">{{q?.createdAt | date:'medium'}}</h5>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <p>Q: {{q.message}}</p>
      </div>
    </div>
    <div *ngIf="q?.response" class="row">
      <div class="col-12">
        <p>R: {{q.response}}</p>
      </div>
    </div>
    <div class="actions-btns" *ngIf="userId === q.user._id">
      <div class="edit" (click)="openModal(editModal, q)">
        <fa-icon [icon]="pencil"></fa-icon>
      </div>
      <div class="delete" (click)="openModal(deleteModal, q)">
        <fa-icon [icon]="trash"></fa-icon>
      </div>
    </div>
  </div>
</div>


<ng-template #deleteModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-title">{{'modal.COMMENT_DELETE_TITLE' | translate}}</h4>
    <button type="button" class="close" aria-describedby="modal-title" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p><strong>{{'modal.COMMENT_DELETE_MESSAGE' | translate}} </strong></p>
    <p>

    </p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="modal.dismiss('cancel click')">{{'cancel' |
      translate}}</button>
    <button type="button" class="btn btn-danger" (click)="deleteMsg()">{{'delete' | translate}}</button>
  </div>
</ng-template>

<!--******-->

<ng-template #editModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-title">{{'modal.COMMENT_EDIT_TITLE' | translate}}</h4>
    <button type="button" class="close" aria-describedby="modal-title" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <form [formGroup]="formEdit" (ngSubmit)="editMsg(formEdit.value)">
    <div class="modal-body">
      <textarea type="text" class="form-control" formControlName="message"
        placeholder="Edit my question about product"></textarea>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-danger" (click)="modal.dismiss('cancel click')">{{'cancel' |
        translate}}</button>
      <button [disabled]="!formEdit.valid" type="submit" class="btn btn-primary">{{'edit' | translate}}</button>
    </div>
  </form>
</ng-template>

<div *ngIf="questions.length > 0" class="mt-3 mb-3">
  <ngb-pagination (pageChange)=" getMessages($event)" [(page)]="page" [pageSize]="pageSize"
    [collectionSize]="collectionSize" [boundaryLinks]="true" [maxSize]="8"></ngb-pagination>
</div>