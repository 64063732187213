<div class="container">
  <drag-scroll #nav> 
    <ng-content></ng-content>
    <div class="movebtn prev-btn" (click)="moveLeft()">
      <fa-icon [icon]="left" size="3x"></fa-icon>
    </div>
    <div class="movebtn next-btn" (click)="moveRight()">
      <fa-icon [icon]="right" size="3x"></fa-icon>
    </div>
  </drag-scroll>
</div>