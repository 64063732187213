import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Language } from 'src/app/models/language.model';

@Component({
  selector: 'app-language',
  templateUrl: './language.component.html',
  styleUrls: ['./language.component.scss']
})
export class LanguageComponent implements OnInit {

  @Output() selectedEmit:EventEmitter<string> = new EventEmitter<string>();
  @Input() isMobile:boolean = false;
  public langs: Language[] = [{ text: "EN", value: "en" }, { text: "ES", value: "es" }]
  public selected: Language = this.langs[0];

  constructor() { }

  ngOnInit(): void {
    let lang = localStorage.getItem('language') ? localStorage.getItem('language') : 'en';
    this.setSelected(lang === 'en' ? this.langs[0] : this.langs[1])

  }

  setSelected(item: Language) {
    this.selected = item;
    this.selectedEmit.emit(item.value);
  }

}
