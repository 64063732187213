import { Component, OnInit } from '@angular/core';
import { faFacebook, faInstagram, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { LegalService } from 'src/app/services/legal.service';
import { SocialService } from 'src/app/services/social.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  public today = Date.now();
  facebook  = faFacebook;
  twitter   = faTwitter;
  instagram = faInstagram;
  youtube = faYoutube;
  public logo = "logoText.png";
  public legalText = [];
  rrss = {
    instagram: '',
    facebook: '',
    twitter: '',
    youtube: ''
  }

  currentYear = ''


  legalData = null;

  constructor(private legalService:LegalService, private configService:SocialService) {
    this.currentYear = new Date(this.today).getFullYear()+''
   }

  ngOnInit(): void {
    this.legalService.list().subscribe(data =>{
      this.legalData = data;
      this.legalText = data["legalText"];
    })

    this.configService.list().subscribe((data:any) =>{
      this.rrss = data;
 
    })
  }

  goToLink(url: string){
    const regx = /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/
    if(!regx.test(url)) return;
    window.open(url, "_blank");
}

}
