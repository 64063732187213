import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LegalService } from 'src/app/services/legal.service';

@Component({
  selector: 'app-legaltext',
  templateUrl: './legaltext.component.html',
  styleUrls: ['./legaltext.component.scss']
})
export class LegaltextComponent implements OnInit {

  private id: string = "";
  public text:{name:string, text:string} = {name : '', text : ''};

  constructor(private route: ActivatedRoute, private legalService:LegalService) { }

  ngOnInit(): void {
    this.route.params.subscribe(params =>{
      this.id = params.id;
      this.getLegal();
    });
  }

  getLegal(){
    this.legalService.list().subscribe(data => {
      const text = data["legalText"].find(val => val._id == this.id);
      if(!text){
        this.text['name'] = data[this.id].name;
        this.text['text'] = data[this.id].text;
        return;
      }

      this.text = text;
    })
  }

}
