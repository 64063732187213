import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Product } from 'src/app/models/product.model';
import { ProductsService } from 'src/app/services/products.service';

@Injectable({
  providedIn: 'root'
})
export class ProductFacadeService {

  private product:Product = this.initProduct();

  private productSource = new BehaviorSubject<Product>(this.product);
  public product$: Observable<Product> = this.productSource.asObservable();
  productImages:any[] = [];

  private productImagesSource = new BehaviorSubject<any[]>(this.productImages);
  public productImages$: Observable<any[]> = this.productImagesSource.asObservable();

  constructor(private productService:ProductsService) {
  }

  setProduct(product:Product){
    this.product = product;
    this.productSource.next(product);
  }

  initProduct(){
    return {
      name: "",
      description: "",
      youtube: "",
      imgs: [],
      length: "",
      height: "",
      width: "",
      weight: "",
      dimensions: "",
      terms: "",
      category: "",
      policies: "",
      delivery_price:"",
      unit: "",
      tags: [],
      price: "",
      price_send: "",
      status: "onSale" // "craft", "onSale", "sold", "canceled", "sended", "recived"
    }
  }

  clear(){
    this.productImages = [];
    this.productImagesSource.next([]);
    this.setProduct(this.initProduct());
  }

  setData(values:any){
    Object.keys(values).map((key) => {
      this.product[key] = values[key];
    })
    this.productSource.next(this.product);
  }

  setImages(images:any[]){
    this.productImages = images;
    this.productImagesSource.next(images);
  }

  save(){
    const files = this.productImages.filter((val:any) => val.file != null).map((val:any) => val.file);
    this.product.imgs = [];
    this.product.terms = this.codeToHtml(this.product.terms);
    this.product.policies = this.codeToHtml(this.product.policies);
    this.product.description = this.codeToHtml(this.product.description);
    this.productImages.forEach(val => {
      if(val.file === null || val.file === undefined ) this.product.imgs.push(val.src);
    })
    return this.productService.createProduct(files, this.product);
  }

  private codeToHtml(val:string){
    return val.replace(/\r?\n/g, '<br />');
  }
}