import { Component, ElementRef, HostListener, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { faCoffee, faUser, faBars } from '@fortawesome/free-solid-svg-icons';
import { TokenService } from 'src/app/services/token.service';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { Observable, Subscription } from 'rxjs';
import { PushNotificationService } from 'src/app/services/notifications/pushnotifications';
import { NotificationRecount } from 'src/app/services/models/notificationRecount';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit, OnDestroy {

  faCoffee = faCoffee;
  faUser = faUser;
  faBars = faBars;
  public isLoged = false;
  public logo = "logo.png";
  times = faTimes;

  me: any = {
    userName: 'User',
    img: './../../../../../assets/img/orange.png'
  };

  notification$:Observable<NotificationRecount>;
  notifs: NotificationRecount;

  private logedSubscription: Subscription;

  constructor(private translate: TranslateService, private renderer: Renderer2, private tokenService: TokenService, private notifications:PushNotificationService) {
    this.notification$ = notifications.notifications$;
    notifications.notifications$.subscribe(notifs => {
      console.log("NOTIFS", notifs)
      this.notifs = notifs
    });
  }

  @ViewChild("leftNav", { static: false }) leftNav: ElementRef;
  @ViewChild("rightNav", { static: false }) rightNav: ElementRef;
  @ViewChild("leftbtn", { static: false }) leftbtn: ElementRef;
  @ViewChild("rightbtn", { static: false }) rightbtn: ElementRef;

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (this.leftbtn.nativeElement.contains(event.target) || this.rightbtn.nativeElement.contains(event.target)) {
      return;
    }

    if (!(this.rightNav.nativeElement.contains(event.target) || this.leftNav.nativeElement.contains(event.target))) {
      this.closeLeft();
      this.closeRight();
    }


  }

  ngOnInit(): void {
    this.logedSubscription = this.tokenService.isLoged.subscribe(data => {
   
      this.isLoged = data;

      if (this.isLoged) {
        this.me = this.tokenService.currentUserValue();
      }
    });
    this.isLoged = this.tokenService.isAuthenticated();
  }

  changelang(lang: string) {
    this.translate.use(lang);
    localStorage.setItem('language', lang);
  }

  openLeft() {
    this.renderer.setStyle(this.leftNav.nativeElement, 'left', '0px');
    this.closeRight();
  }

  openRight() {
    this.renderer.setStyle(this.rightNav.nativeElement, 'right', '0');
    this.closeLeft();
  }

  closeLeft() {
    this.renderer.setStyle(this.leftNav.nativeElement, 'left', '-250px');
  }

  closeRight() {
    this.renderer.setStyle(this.rightNav.nativeElement, 'right', '-250px');
  }

  ngOnDestroy(): void {
    this.logedSubscription.unsubscribe();
  }


}
