import { Component, ElementRef, OnInit, Output, ViewChild, EventEmitter, Input } from '@angular/core';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-taginput',
  templateUrl: './taginput.component.html',
  styleUrls: ['./taginput.component.scss']
})
export class TaginputComponent implements OnInit {

  faTimes = faTimesCircle;
  tagValue = '';
  keys = [',', '.','Enter'];
  tagList = [];
  autocompleteList = [];
  recomendedList = [];

  @Input() data: any[] = [];

  @ViewChild('taginput') taginput: ElementRef;
  @Output() tagsEmit = new EventEmitter<string[]>();

  constructor() {

  }

  ngOnInit(): void {
    this.tagList = this.data.map(tag => tag.label ? tag.label : tag);
  }

  onPress($event) {
    if (!this.tagValue && $event.keyCode === 8) this.delete(this.tagList.length - 1);
  }

  onUp($event) {
    console.log('|||||||',$event);
    let addElement = false;
    this.recomendedList = [];
    const char = this.tagValue[this.tagValue.length - 1];
    if (!char) {
      return;
    }

    if (this.keys.includes(char)) {
      addElement = true;
      this.tagValue = this.tagValue.replace(char, '');
    }

    this.searchAutocomplete();

    if (($event.keyCode === 13 || addElement) && this.tagValue !== '') {
      $event.preventDefault();
      this.addTag(this.tagValue);
    }
  }

  addTag(value: string) {
    this.tagList = [...this.tagList, value];
    this.recomendedList = [];
    this.tagValue = '';
    this.tagsEmit.emit(this.tagList);
  }

  delete(idx: number) {
    this.tagList.splice(idx, 1);
    this.tagsEmit.emit(this.tagList);
  }

  searchAutocomplete() {
    this.recomendedList = this.autocompleteList.filter(val => val.toLocaleLowerCase().includes(this.tagValue.toLocaleLowerCase()));
  }

}
