<div class="container-fluid pt-5 pb-5">
    <div class="empty" *ngIf="contacts.length === 0">
        <div class="row">
            <div class="col-12">
                <img src="./../../../../assets/icons/chat.png" alt="no Chat">
                <h1>{{"no_messages_yet" | translate}}</h1>
            </div>
        </div>
    </div>
    <div *ngIf="data.length > 0">
        <div class="row d-md-flex">
            <div class="col-12 col-md-4 margen" *ngIf="(isMobile && showContacts) || !isMobile">
                <app-messagelist [isMobile] = "isMobile" [notificationList]="notificationList" [contacts]="contacts"
                    (selectedChat)="getConversation($event)"></app-messagelist>
            </div>
            <div class="col-12 col-md-8" *ngIf="(isMobile && !showContacts) || !isMobile">
                <app-messagepanel [isMobile] = "isMobile" [(showContacts)] = "showContacts" [iAm]="iAm" [msgs]="messages" (sendEmit)="writeMessage($event)"></app-messagepanel>
            </div>
        </div>
    </div>
</div>