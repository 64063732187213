import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Product } from 'src/app/models/product.model';

@Component({
  selector: 'app-checkout-product',
  templateUrl: './checkout-product.component.html',
  styleUrls: ['./checkout-product.component.scss']
})
export class CheckoutProductComponent implements OnInit, OnChanges {

  @Input() product: Product = null;
  productPrice = '0';
  deliveryPrice = '0';
  totalPrice = '0';


  constructor() { }
  ngOnChanges(changes: SimpleChanges): void {
    if (!this.product) return;
    this.productPrice = parseFloat(this.product.price).toFixed(2);
    this.deliveryPrice = parseFloat(this.product.delivery_price || '0').toFixed(2);
    this.totalPrice = parseFloat(parseFloat(this.productPrice) + parseFloat(this.deliveryPrice) + '').toFixed(2);
  }

  ngOnInit(): void { }

}
