import { Component, Input, Output, EventEmitter, OnChanges, HostListener, ElementRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { DynamicFormModel } from './models/dynamicform.model';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { TranslateService } from '@ngx-translate/core';
import { esLocale } from 'ngx-bootstrap/locale';

import { defineLocale } from 'ngx-bootstrap/chronos';

@Component({
  selector: 'app-dynamicform',
  templateUrl: './dynamicform.component.html',
  styleUrls: ['./dynamicform.component.scss']
})
export class DynamicformComponent implements OnChanges {

  @HostListener('document:keydown.enter', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if(this.el.nativeElement.ownerDocument.activeElement.id === 'tagInput') return;
    this.send();
  }

  @Output() valuesEmit: EventEmitter<any> = new EventEmitter();
  @Input() form: any;
  @Input() data: any;
  @Input() buttons: any = null;
  @Input() categoryValidation = true;
  public formGroup: FormGroup;

  constructor(private localeService: BsLocaleService, private translate: TranslateService, private el: ElementRef) {
    let locale = this.translate.currentLang;

    if (locale == 'es') {
      defineLocale('es', esLocale);
    } else {
      defineLocale('en');
    }

    this.localeService.use(locale);
  }

  applyLocale(pop: any) {
    this.localeService.use(this.translate.currentLang);
    pop.hide();
    pop.show();
  }

  ngOnChanges(): void {
    if (!this.form) return;
    this.initForm()
  }

  initForm() {
   
    this.formGroup = this.toFormGroup(this.form);
    if (this.data) {
      this.formGroup.patchValue(this.data);
    };
  }

  toFormGroup(questions: DynamicFormModel[]) {
    const group: any = {};
    const arr_obj = [];
    questions.forEach(question => {
      question.inputs.forEach(input => {
        if (/*input.type === 'category' ||*/ input.type === 'file') return;
        //arr_obj.push(this.transformAninadeObject(input.key, input.formBuild.default, input.formBuild.validators));
        //input.key.split(".").map(val =>{
        group[input.key] = new FormControl(input.formBuild.default, input.formBuild.validators)
        //})
      });
    });
    /* var bigObj = arr_obj.reduce(function (o, n) {
       for (var key in n) o[key] = n[key];
       return o;
     }, {});*/


    return new FormBuilder().group(group);
  }

  transformAninadeObject = (string, object, validators) => {
    var parts = string.split(".");
    var last = parts[parts.length - 1];
    var tree = {};

    var node = parts.slice(0, -1).reduce(function (memo, current) {
      return (memo[current] = new FormBuilder().group({}));
    }, tree);

    node[last] = new FormControl(string, validators);

    return tree;
  };

  changeData() { }

  getValues() {
    return this.formGroup.value;
  }

  categoryEmit = (input, $event) => {
    this.data = this.getValues();
    let categoryControl = this.formGroup.controls.category
    categoryControl.markAsTouched();
    if ($event)
      categoryControl.setErrors(null)
    else
      categoryControl.setErrors({ 'required': true })
    this.data.category = $event;
    this.formGroup.patchValue(this.data);
    input?.callback($event)
  }

  send() {
    if(!this.formGroup.valid) return;
    const button = this.buttons.buttons.filter(b => b.callback);
    if (!button) return;
    button[0].callback(this.getValues());
  }

  check(item: any) {
    console.log(item);
  }
}
