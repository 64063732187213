import { Component, OnInit, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { faVolleyballBall } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../services/auth.service';
import { DynamicFormModel } from '../shared/components/dynamicform/models/dynamicform.model';
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  volley = faVolleyballBall;
  @ViewChild('emailSended') emailModal;

  public buttonsData: any = {
    parentClass: 'mt-4 mb-5',
    buttons: [
      {
        hasDisabled: true,
        cssContainer: "",
        cssInput: "btn btn-primary text-white",
        title: "sign_up",
        callback: (values) => {
          if (this.validateEmail(values.email)) {
            this.register(values);
            localStorage.setItem('user', values.email);
          } else {
            let em = (<HTMLInputElement>document.getElementById('email'));
            em.classList.add("is-invalid");
          }

        }
      }
    ]
  }


  public formValue: DynamicFormModel[] = [
    {
      parentClass: 'row',
      inputs: [
        {
          classFormGroup: 'col-12',
          classInput: '',
          key: 'userName',
          type: 'text',
          placeholder: 'info.username',
          formBuild: {
            default: "",
            validators: [Validators.required]
          }
        },
        {
          classFormGroup: 'col-12',
          classInput: '',
          key: 'email',
          type: 'email',
          placeholder: 'email',
          formBuild: {
            default: "",
            validators: [Validators.email]
          }
        },
        {
          classFormGroup: 'col-12',
          classInput: '',
          key: 'password',
          type: 'password',
          placeholder: 'placeholder.password',
          formBuild: {
            default: "",
            validators: [Validators.required]
          }
        },
        {
          classFormGroup: 'col-12',
          classInput: '',
          key: 'checkbox',
          type: 'checkbox',
          placeholder: 'sing_up_message',
          formBuild: {
            default: '',
            validators: [Validators.requiredTrue]
          }
        },
        /*{
          classFormGroup: 'col-12',
          classInput: '',
          key: 'captcha',
          type: 'captcha',
          formBuild: {
            default: '',
            validators: [Validators.required]
          }
        }*/
      ]
    }
  ];

  constructor(private authService: AuthService,
     private router: Router,
     private modalService: NgbModal,
     private translateService: TranslateService) { }


  ngOnInit(): void {
  }

  register($event) {
    this.authService.register($event).subscribe(isOk => {
      this.openEmailModal();
    });
  }

  openEmailModal(){
    this.modalService.open(this.emailModal, { ariaLabelledBy: 'modal-basic-title' }).dismissed.subscribe(_v=> {
      this.router.navigate(['/login']);
    })
  }

  validateEmail(email: string) {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);

  }

}
