import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Category } from 'src/app/models/category.model';


@Component({
  selector: 'menu-item-category',
  templateUrl: './item-category.component.html',
  styleUrls: ['./item-category.component.scss']
})
export class ItemCategoryComponent implements OnInit {

  @Input() item:Category
  @Input() level:number=0
  @Input() callback:any = null;
  @Output() clickItemCategory: EventEmitter<Category> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  clickItem(category:Category){
    if(this.callback){
      this.callback(category)
    }
  }
}
