<div #header_par [ngClass] = "{'no-parallax': !haveParalax}" class="parallaxh" id="header_par" [style.background-image]="'url(' + data.backgroundimg  + ')'">
	<div class="overlay">
		<div class="container-fluid align-bottom">
			<div class="content">
				<div class="row item">
					<div class="col-4 col-md-2 col-xl-1">
						<div class="avatar" [style.background-image]=" data?.img ? 'url(' + data.img  + ')' : 'url('+('orange.png' | assetsRoute:'img')+')' "></div>
					</div>
					<div class="col-6 col-md-5">
						<div class="row">
							<div class="col-12">
								<h3 class="store mt-4 pt-2">{{data.title}}</h3>
							</div>
							<div class="col-12">
								<div class="row" *ngIf="data.idStore">
								<div class="col-12 col-md-3">
									<button type="button" (click)="goToStore( data.idStore )" class="btn btn-secondary">
										View </button>
								</div>
								<div class="col-12 col-md-2">
									<label> {{rate}} <fa-icon [icon]="star"></fa-icon></label>
								</div>
							</div>
							</div>
							
						</div>
					</div>
					<div class="col-12 col-md-5 text mt-4" *ngIf="data.isMine">
						<a class="btn btn-light btn-lg" [routerLink]="['settings']">Editar</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>