import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'CurrencyPipe'
})
export class CurrencyPipe implements PipeTransform {

  transform(currency: any): string {
    if(!currency) return '€';
    return (currency.toLocaleUpperCase() === 'DOLAR' || currency.toLocaleUpperCase() === 'USD')  ? '$' : '€';
  }

}