<div class="container mt-5">
  <div class="row">
    <div class="col-12 text-center mt-5 ">
      <h1>{{ 'sended_msg' | translate }}</h1>
      <br />
      <img src="./assets/img/logo.png">
    </div>
  </div>

  <div class="row">
    <div class="col-12 text-center mt-5 mb-5">
      <h3>{{'we_response' | translate}}</h3>
    </div>
  </div>
  <!--6LceUrAfAAAAAKzkB4M_9QjGQWFQtE0wVDWAAhmL-->