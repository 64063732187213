<div class="container-fluid pt-5">
  <div class="row">

    <div class="col-12 col-md-3">
      <h4>{{category}}</h4>
      <menu-categories [categories]="categories" [level]='0' (clickCategory)="clickItem($event)"></menu-categories>
    </div>
    <div class="col-12 col-md-9">

      <div class="col-12 text-center mt-5 mb-5">
        <div class="row">
          <app-cardlist (changepageEmit) = "getProducts($event)" [data]="products" [page]="page" [pageSize]="pageSize" [collectionSize]="collectionSize">
          </app-cardlist>
        </div>
      </div>
    </div>
  </div>
</div>
