<div class="logged-container">
    <a [routerLink]="['wishlist']" class="whishlist-menu uptext">
        <fa-icon [icon]="faBookmark" class="mr-2 primary"></fa-icon>
    </a>

    <div class="avatar-container uptext">
        <div class="avatar-img-container"
            [style.background-image]="img ? 'url(' +img + ')' : 'url('+('orange.png' | assetsRoute:'img')+')' ">

        </div>
        <div>
            <div class="btn-group">
                <button #btnMenu type="button" class="btn btn-sm dropdown-toggle username" data-toggle="dropdown"
                    aria-haspopup="true" aria-expanded="false">
                    {{ userName }}

                    <div *ngIf="recount?.total > 0" class="global-badge">
                        <fa-icon [icon]="notification"></fa-icon>
                        <p class="text-count">
                            {{recount?.total}}
                        </p>
                    </div>
                </button>
              

                <div class="dropdown-menu">
                    <a class="dropdown-item" [routerLink]="['account']">
                        <fa-icon [icon]="iconProfile" class="mr-2 primary"></fa-icon>{{'account_menu.myaccount' |
                        translate}}
                        <span *ngIf="recount?.account?.total > 0"
                            class="badge badge-pill badge-primary item-badge">{{recount?.account?.total}}</span>
                    </a>
                    <a class="dropdown-item store-item" (click)="store()">
                        <fa-icon [icon]="iconStoreSession" class="mr-2 primary"></fa-icon> {{'account_menu.mystore' |
                        translate}}
                        <span *ngIf="recount?.store?.total > 0"
                            class="badge badge-pill badge-primary item-badge">{{recount?.store?.total}}</span>
                    </a>
                    <a class="dropdown-item" [routerLink]="['wishlist']">
                        <b>
                            <fa-icon [icon]="faBookmark" class="mr-2 primary"></fa-icon> Wishlist
                        </b>
                    </a>
                    <a class="dropdown-item logout-item" (click)="logout()">
                        <fa-icon [icon]="iconCloseSession" class="mr-2 primary"></fa-icon>{{'account_menu.logout' |
                        translate}}
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>