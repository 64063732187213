import { Component, Input, EventEmitter, Output, ViewChild } from '@angular/core';
import { NgxPicaErrorInterface, NgxPicaResizeOptionsInterface, NgxPicaService } from '@digitalascetic/ngx-pica';
import { NgbCarousel, NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { faTimes, faPlus } from '@fortawesome/free-solid-svg-icons';

import SwiperCore, { SwiperOptions } from 'swiper';
import { SwiperComponent } from 'ngx-useful-swiper';

interface ImageSnippet {
  src: string,
  file: File
}
const options: NgxPicaResizeOptionsInterface = {
  exifOptions: {
    forceExifOrientation: true
  },
  aspectRatio: {
    keepAspectRatio: true,
  },
};

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent {

  @ViewChild('swiper') swiper: SwiperComponent;

  @Input() images:ImageSnippet[] = [];
  faTimes = faTimes;
  faPlusCirle = faPlus;
  public current = 0;
  public loading = false;
  hasVideo = true;
  @Input() isEdit = false;
  @ViewChild('carousel') carousel:NgbCarousel;
  @Output() imgUpdateEmit = new EventEmitter<ImageSnippet[]>();

  configSwiper: SwiperOptions = {
    pagination: { 
      el: '.swiper-pagination', 
      clickable: true
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    spaceBetween: 30
  };  
  constructor(config: NgbCarouselConfig, private _ngxPicaService: NgxPicaService) {
    config.interval = 10000;
    config.wrap = false;
    config.keyboard = false;
    config.pauseOnHover = false;
  }

  slide(event) {
    this.current = parseInt(event.current[event.current.length - 1]);
  }

  resizeFile(file: File) {
    this.loading = true;
    this._ngxPicaService.compressImage(file, 1, options).subscribe(
      (imageResized: File) => {
        this.loading = false;
        const reader: FileReader = new FileReader();

        reader.addEventListener(
          'load',
          (event: any) => {
            //this.dataPreview = event.target.result;
            this.images.push({ src: event.target.result, file: imageResized });
            this.imgUpdateEmit.emit(this.images);
          },
          false
        );

        reader.readAsDataURL(imageResized);
      },
      (err: NgxPicaErrorInterface) => {
        this.loading = false;
        throw err.err;
      }
    );
  }

  expandImage = (url: string) => {
    let modal = document.getElementById("myModal");
    modal.style.display = "block";
    this.swiper.swiper.update();
  }

  closeImgModal = () => {
    let modal = document.getElementById("myModal");
    modal.style.display = "none";
  }

  deleteFile(index: number) {
    this.images.splice(index, 1);
    this.imgUpdateEmit.emit(this.images);
  }

  selectImg(i: number) {
    this.carousel.select('ngb-slide-'+i)
  }

}
