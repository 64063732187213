import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Store } from '../models/store.model';
import { StoreService } from './store.service';

@Injectable({
  providedIn: 'root',
})
export class PublicStoreService {
  @Input() store: Store = {
    _id:'',
    products: [],
    contact: {
      city: '',
      country: '',
    },
  };

  private storeSelected = new BehaviorSubject<Store>(this.store);
  public store$: Observable<Store> = this.storeSelected.asObservable();

  constructor(private storeService: StoreService) {}

  setStore = () => {
    this.storeService.getMyStore().subscribe((isOk) => {
      this.store = isOk;
      this.storeSelected.next(this.store);
    });
  };

  getStore = () => {
    return this.store;
  };

  getPublicStore = (id:string) =>{
    this.storeService.get(id).subscribe((isOk)=>{
      this.store = isOk;
      this.storeSelected.next(this.store);
    })
  }
}
