import { Injectable } from "@angular/core";
import { BaseService } from "./common/base.service";
import { LoadingService } from './loading.service';
import { HttpClient, HttpEvent, HttpHeaders, HttpRequest } from "@angular/common/http";
import { ErrorService } from "./common/error.service";
import { TokenService } from './token.service';
import { ToastService } from './common/toast.service';
import { Observable } from 'rxjs';
import { Token } from "../models/token.model";
import { User } from "../models/user.model";

@Injectable({
  providedIn: 'root'
})
export class AuthService extends BaseService {

  constructor(public http: HttpClient, public error: ErrorService, public token: TokenService, public toast: ToastService, public loadingService: LoadingService) {
    super(http, error, token, toast, loadingService);
    this.host = this.host + "auth";
  }

  login(user: any) {
    let url = this.host + "/login";
    let body = {
      email: user.email,
      password: user.password
    };

    const observer = new Observable(observer => {

      this.http.post<Token>(url, body).subscribe(
        data => {
          this.tokenService.save(data['data']);
          observer.next(true);
          observer.complete();
        },
        err => {
          console.log("ERRORx", err);
          this.error.error(err);
          this.token.logout();
          observer.next(false);
          observer.complete();
        }
      );
    });

    return observer;
  }

  register(user: User) {
    let url = this.host + "/register";
    return this.doPost(url, user, false);
  }

  recovery(email: any) {
    let url = this.host + "/recovery";
    return this.doPost(url, email, false);
  }

  getMyData() {
    const url = this.host + '/me';
    return this.doGet(url);

  }

  updateMyData(img: File, user: User) {
    const formData: FormData = new FormData();
    const headers = new HttpHeaders()
      .set("Authorization", this.tokenService.get());

    formData.append('data', JSON.stringify(user));

    if (img) {
      formData.append('img', img);
    }

    const req = new HttpRequest('PUT', `${this.host}`, formData, {
      reportProgress: true,
      responseType: 'json',
      headers: headers,
    });

    return this.http.request(req);
  }

  getAddresses = () => {
    const url = this.host + '/address';
    return this.doGet(url);
  }

  createAddress = (data) => {
    const url = this.host + '/address';
    return this.doPost(url, data, false);

  }

  deleteAddress = (id) => {
    const url = this.host + '/address/' + id;
    return this.doDelete(url);
  }

  updateAddress = (data) => {
    const url = this.host + '/address';
    return this.doPut(url, data, false);
  }

  selectAddress = (id: string, showloading = true) => {
    const url = this.host + '/address/' + id + '/active';
    return this.doPost(url, { test: false }, showloading);
  }

  saveDeviceToken(devicetoken: string) {
    const url = this.host + '/deviceToken/';
    return this.doPost(url, { token: devicetoken }, false);
  }

  removeDeviceToken(devicetoken: string) {
    const url = this.host + '/deviceToken/' + devicetoken;
    return this.doDelete(url, false);
  }


}
