import { Injectable } from "@angular/core";
import { BaseService } from "./common/base.service";
import { LoadingService } from './loading.service';
import { HttpClient } from "@angular/common/http";
import { ErrorService } from "./common/error.service";
import { TokenService } from './token.service';
import { ToastService } from './common/toast.service';

@Injectable({
  providedIn: 'root'
})
export class QuestionsService extends BaseService {

  constructor(public http: HttpClient, public error: ErrorService, public token: TokenService, public toast: ToastService, public loadingService: LoadingService) {
    super(http, error, token, toast, loadingService);
    this.host = this.host + "product/questions";
  }

  getQuestions(idProduct: string, page = 1) {
    const orderBy = JSON.stringify([{field:'createdAt', orderBy:"DESC"}])
    const url = this.host + '/' + idProduct + '?page=' + page + '&limit=10&order='+ orderBy;
    return this.doGet(url);
  }

  sendQuestion(idProduct: string, message: string) {
    const url = this.host + '/' + idProduct;
    return this.doPost(url, { message });
  }

  sendResponse(idquestion: string, message: string) {
    const url = this.host + '/response/' + idquestion;
    return this.doPost(url, { message });
  }
}

