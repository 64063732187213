import { Component, OnInit, ViewChild } from '@angular/core';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { DragScrollComponent } from 'ngx-drag-scroll';

@Component({
  selector: 'app-swipelist',
  templateUrl: './swipelist.component.html',
  styleUrls: ['./swipelist.component.scss']
})
export class SwipelistComponent implements OnInit {
  @ViewChild('nav', { read: DragScrollComponent }) ds: DragScrollComponent;
  left = faAngleLeft;
  right = faAngleRight;


  constructor() { }

  ngOnInit(): void {
  }

  moveLeft() {
    this.ds.moveLeft();
  }

  moveRight() {
    this.ds.moveRight();
  }

  moveTo(index) {
    this.ds.moveTo(index);
  }

  ngAfterViewInit() {
    this.loadDrag();
  }

  loadDrag() {
    
    setTimeout(() => {
      this.ds.moveTo(1);
    }, 0);
  }

}
