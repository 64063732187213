import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Address } from 'src/app/models/address.model';
import { AuthService } from 'src/app/services/auth.service';
import { TokenService } from 'src/app/services/token.service';
import { } from 'stream';

@Component({
  selector: 'app-checkout-address',
  templateUrl: './checkout-address.component.html',
  styleUrls: ['./checkout-address.component.scss']
})
export class CheckoutAddressComponent implements OnInit {
  @Input() address: Address = null;
  @Output() addressChange = new EventEmitter<string>();
  addresses: Address[] = [];
  check = faCheck;

  constructor(private auth: AuthService, private tokenService: TokenService, private modalService: NgbModal) {
  }

  ngOnInit(): void {
    this.auth.getAddresses().subscribe((data: Address[]) => {
      this.bindData(data);
    })
  }

  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' });
  }

  changeStatus(id: string) {
    this.auth.selectAddress(id, false).subscribe((data: Address[]) => {
      this.bindData(data);
    });
  }

  bindData(data: Address[]) {
    this.address = data.find(address => address.active);
    this.addressChange.emit(this.address?._id || '');
    this.addresses = data;
  }

}
