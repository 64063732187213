import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-paginate',
  templateUrl: './paginate.component.html',
  styleUrls: ['./paginate.component.scss']
})
export class PaginateComponent implements OnInit {
  @Input() page = 1
  @Input() pageSize = 2
  @Input() collectionSize = 500

  constructor() { }

  ngOnInit(): void {
  }

}
