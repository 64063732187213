
<div class="container">
  <div class="row">
    <div class="col-12 upload-container">
      <div class="content">
        <div *ngIf="selectedFile?.src || data && isEdit" class="close" (click)="deleteImage();">
          <fa-icon [icon]="faTimes"></fa-icon>
        </div>
  
        <label>
          <div class="imagePreview" [style.background-color] = "selectedFile?.src || data ? 'transparent' : '#ed8a10'" [ngClass] ="!selectedFile?.src ? 'nopick' : 'haspick'" 
          [style.background-image]="  selectedFile?.src  ? 'url(' + selectedFile.src  + ')' : data ? 'url(' + data  + ')' : 'url(' + ('logoText.png' | assetsRoute:'img')  + ')'">
            <div *ngIf="loading" class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
          <span *ngIf="isEdit" class="btn btn-light">{{'select_pic' | translate}}</span>
          <div *ngIf="!isEdit" (click)="isEditChange.emit(!isEdit)">
            <span  class="btn btn-primary">{{'edit' | translate}}</span>
          </div>
          <input *ngIf="isEdit" #imageInput type="file" accept="image/*" (change)="resizeFile(imageInput.files[0])">
        </label>
      </div>
    </div>
  </div>

</div>