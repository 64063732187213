<div class="carrusel-container" [ngClass]="{'noVideo': hasVideo}">
    <div *ngIf="images.length === 0" class="no-images picsum-img-wrapper">
        <div *ngIf="!isEdit">
            <p class="no-imgs">{{"product.no_images" | translate}}</p>
            <img class="logo" src="{{'logoText.png' | assetsRoute:'img'}}" />
        </div>
        <label class="add-image-content" *ngIf="isEdit">
            <fa-icon class="addIcon" [icon]="faPlusCirle"></fa-icon>
            <p>{{'add_image' | translate}}</p>
            <input #imageInput type="file" (change)="resizeFile(imageInput.files[0])" accept="image/*">
        </label>
    </div>
    <div *ngIf="images.length > 0">
        <ngb-carousel #carousel *ngIf="images" [showNavigationIndicators]="false" (slide)="slide($event)">
            <ng-template *ngFor="let i of images; let idx = index" ngbSlide>
                <div class="picsum-img-wrapper" [ngClass]="{'noVideo': hasVideo}"
                    [style.background-image]="'url(' +i.src  + ')'" (click)="expandImage(i.src)">
                    <!--img [src]="i.src" alt="Random first slide"-->
                </div>
            </ng-template>
        </ngb-carousel>
        <div class="row list">
            <div (click)="selectImg(i)" class="col-3" *ngFor="let image of images; let i = index"
                activeId="{{'slide'+i}}">
                <div *ngIf="isEdit" (click)="deleteFile(i)" class=" col-img">
                    <fa-icon class="close" [icon]="faTimes"></fa-icon>
                </div>
                <div [ngClass]="{'selected': i === current}" [style.background-image]="'url(' + image.src + ')'"
                    class="img-list"></div>
            </div>
            <div *ngIf="images.length < 4 && isEdit" class="col-3 col-img text-center">
                <label>
                    <fa-icon [icon]="faPlusCirle"></fa-icon>
                    <div *ngIf="loading" class="spinner-border" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <input #imageInput type="file" (change)="resizeFile(imageInput.files[0])" accept="image/*">
                </label>
            </div>
        </div>
    </div>
</div>

<!-- The Modal -->
<div id="myModal" class="modal">
    <span class="close2" (click)="closeImgModal()">&times;</span>
    <swiper #swiper [config]="configSwiper">
        <div class="swiper-wrapper">
            <div class="swiper-slide" *ngFor="let image of images">
                <div>
                    <img class="img-fluid" [src]="image.src" [alt]="image.src">
                </div>
            </div>
        </div>

        <!-- Add Arrows -->
        <div class="swiper-button-next"></div>
        <div class="swiper-button-prev"></div>
    </swiper>

</div>