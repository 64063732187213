import { Injectable } from "@angular/core";
import { BaseService } from "./common/base.service";
import { LoadingService } from './loading.service';
import { HttpClient } from "@angular/common/http";
import { ErrorService } from "./common/error.service";
import { TokenService } from './token.service';
import { ToastService } from './common/toast.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationService extends BaseService {

  constructor(public http: HttpClient, public error: ErrorService, public token: TokenService, public toast: ToastService, public loadingService: LoadingService) {
    super(http, error, token, toast, loadingService);
    this.host = this.host + "notifs";
  }

  getNotifications() {
    return this.doGet(this.host, false);
  }

  setViewed(body: { _id: string, viewed: boolean }) {
    let url = `${this.host}/viewed`;
    return this.doPost(url, body, false);
  }
}

