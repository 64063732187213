<a href="/product/{{product._id}}">
<div class="file-container">
	<div class="container-yt-related">
		
		<div class="video-container">
			<div class="pic"
				[style.background-image]="product.imgs.length > 0 ? 'url(' +product?.imgs[0] + ')' : 'url('+('orange.png' | assetsRoute:'img')+')' ">
			</div>
		</div>
		<div class="details-container">
			<div class="details">
				<span class="title">{{product?.name}} </span>
			</div>
			<div class=price> {{product.price}}€</div>
		</div>

	</div>
</div>
</a>