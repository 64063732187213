<ng-container *ngIf="form">
  <form class="contact-form" [formGroup]="formGroup">
    <div *ngFor="let parent of form" class="{{parent.parentClass}}">
      <div class="form-group {{input?.classFormGroup}}" *ngFor="let input of parent.inputs">

        <label
          [ngClass]="{'err' : formGroup?.controls[input.key]?.touched && formGroup?.controls[input.key]?.status === 'INVALID'}">{{input?.label
          | translate}}<ng-container *ngIf="input?.currency">({{input?.currency | CurrencyPipe }})</ng-container>
        </label>
        <app-categoryselect (categoryEmit)=" categoryEmit(input, $event)" *ngIf="input.type == 'category'">
        </app-categoryselect>

        <app-taginput [data]="data.tags" (tagsEmit)="input?.callback($event)" *ngIf="input.type == 'taginput'">
        </app-taginput>
        <app-uploadimages (uploadEmit)="input?.callback($event)" [data]="data ? data[input.key] : '' "
          *ngIf="input.type == 'file'"></app-uploadimages>
        <!--app-fileinput [callback] = "input.callback" [key] = "input.key" *ngIf="input.type == 'file'"></app-fileinput-->
        <input
          *ngIf="input.type == 'text' || input.type == 'number' || input.type == 'email' || input.type == 'password'"
          id="{{input.key}}" class="form-control {{input?.classInput}}" type="{{input.type}}" name="{{input.key}}"
          aria-describedby="{{input.key}}" placeholder="{{input.placeholder | translate}}"
          formControlName="{{input.key}}">
        <!-- <input *ngIf="input.type == 'date'" id="{{input.key}}" class="{{input?.classInput}} form-control" type="{{input.type}}" name="{{input.key}}" aria-describedby="{{input.key}}" [placeholder]="input.placeholder | translate" formControlName="{{input.key}}"> -->

        <input *ngIf="input.type == 'date'" id="{{input.key}}" class="form-control {{input?.classInput}} "
          #datepickerYMD="bsDatepicker" bsDatepicker formControlName="{{input.key}}"
          [placeholder]="input.placeholder | translate">

        <select *ngIf="input.type == 'select'" class="form-control {{input?.classInput}} "
          formControlName="{{input.key}}" id="{{input.key}}">
          <option *ngFor="let opt of input.options" [value]="opt.value">{{opt.label | translate}}</option>
        </select>

        <textarea *ngIf="input.type == 'textarea'" class="form-control {{input?.classInput}}" [cols]="input.cols"
          [rows]="input.rows" formControlName="{{input.key}}" id="{{input.key}}"
          [placeholder]="input.placeholder | translate"></textarea>

        <div *ngIf="input.type === 'captcha'" class="captcha">
          <ngx-recaptcha2 #captchaElem formControlName="captcha" siteKey="6LdDXLcgAAAAAF4PyrrORI9W-NJXi_Erdo8SRxTr">
          </ngx-recaptcha2>
        </div>

        <div class="form-check" *ngIf="input.type == 'checkbox'">
          <input class="form-check-input" type="checkbox" value="" id="{{input.key}}" formControlName="{{input.key}}">
          <label class="form-check-label" for="{{input.key}}">
            {{input.placeholder | translate}}
          </label>
        </div>

      </div>
    </div>

    <div *ngIf="buttons" class="{{buttons.parentClass}}">
      <div *ngFor="let b of buttons.buttons; let i = index" class="form-group text-center {{b?.cssContainer}}">
        <input type="button" [attr.disabled]="b.hasDisabled && !formGroup.valid  ? 'disabled' : null" id="{{'btn'+i}}"
          class="btn btn-send-message btn-md {{b?.cssInput}}" [value]="b.title | translate"
          (click)="b.callback(formGroup.value)">
      </div>
    </div>
  </form>
</ng-container>