<app-header [data]="data" [rate]="rate"></app-header>
<div class="marginT container-fluid mb-4">
  <div class="row">
    <div class="col-12 col-lg-9">
      <div class="row">
        <div class="col-12 col-lg-5 order-2 order-lg-1 text-center"
          [ngClass]="product?.youtube ? 'col-lg-5' : 'col-lg-12' ">
          <app-gallery [images]="images"></app-gallery>
        </div>
        <div *ngIf="product?.youtube" class="col-12 col-lg-7 order-1 order-lg-2">
          <iframe width="100%" height="350" [ngClass]="{'iframeIg': isIgVideo}" [src]="product?.youtube | safeurl"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen></iframe>
        </div>
      </div>

      <div class="row">
        <div class="col-12  col-md-6 col-lg-5 order-2 order-lg-1 text-center mTop">
          <div class="card">
            <ul class="list-group list-group-flush text-left">
              <li class="list-group-item"><strong>{{"product.weight" | translate}} :</strong> {{ product?.weight }}
                {{product?.unit.split('_')[0]}}
              </li>
              <li class="list-group-item"><strong>{{"product.dimensions" | translate}} :</strong><br>
                <i> W :</i> {{ product?.width }} {{product?.unit.split('_')[1]}}
                x <i>H :</i> {{ product?.height }} {{product?.unit.split('_')[1]}}
                x <i>L :</i> {{ product?.length }} {{product?.unit.split('_')[1]}}
              </li>
            </ul>
          </div>
        </div>

        <div class="col-12 col-md-6 col-lg-7 mTop order-1 order-lg-2">
          <div class="info-header">
            <div>
              <h4 class="product-title">{{ product?.name }}</h4>
            </div>
            <div class="options-buttons">
              <ng-container *ngIf="isLogged && !isInWishlist">
                <span (click)="addToWishlist( product._id )">
                  <fa-icon [icon]="wishlist" class="fa-2x lightBlue"></fa-icon>
                </span>
              </ng-container>

              <ng-container *ngIf="isLogged && isInWishlist">
                <span (click)="removeFromWishlist( product._id )">
                  <fa-icon [icon]="solidWishList" class="fa-2x color lightBlue"></fa-icon>
                </span>

              </ng-container>

              <div (mouseenter)="openShare(true)" (mouseleave)="openShare(false)">
                <fa-icon *ngIf="!showShare" [icon]="share" class="fa-2x lightBlue"></fa-icon>
                <ng-container *ngIf="showShare" class="text-center mt-2">
                  <a href="https://www.facebook.com/sharer/sharer.php?u={{domain}}/product/{{product._id}}"
                    target="_blank">
                    <fa-icon [icon]="facebook" class="fa-2x lightBlue"></fa-icon>
                  </a>

                  <a href="https://twitter.com/intent/tweet?url={{domain}}/product/{{product._id}}" class="ml-3"
                    target="_blank">
                    <fa-icon [icon]="twitter" class="fa-2x lightBlue"></fa-icon>
                  </a>
                </ng-container>
              </div>

            </div>
          </div>
          <div class="info-content">
            <div class="left">
              <div class="category-content">
                <div class="lightBlue">{{"product.category" | translate}}:</div>
                <label class="categoryLabel"> <a [routerLink]="['/category/', product?.category.slug]"> {{
                    product?.category.label
                    }}</a></label>
              </div>
              <div class="tags-content">
                <div class="lightBlue">Tags:</div>
                <div class="tags-container">
                  <div class="tags-items" *ngFor="let t of product?.tags">
                    <p>
                      <span class="badge badge-pill badge-primary">{{t.label}}</span>
                    </p>
                  </div>
                </div>


              </div>
              <div class="price-container">
                <div class="lightBlue">{{'product.price' | translate}}: </div>
                <span class="price">{{product?.price }} {{product?.store?.currency |
                  CurrencyPipe}}
                </span>
              </div>

            </div>

            <div class="right">
              <button *ngIf="product?.status === 'onSale'" class="btn btn-primary btn-lg"
                [routerLink]="['/checkout', product?._id]">{{'product.buy' | translate }}</button>
              <button *ngIf="product?.status !== 'onSale'" class="btn btn-danger btn-lg" disabled="true">{{"soldOut" |
                translate}}</button>
            </div>
          </div>

        </div>

      </div>
    </div>

    <div class="col-md-12 col-lg-3 mTop d-none  d-lg-block">
      <div class="row">
        <div class="col-md-6 col-lg-12">
          <div class="borderOrange m-1 card">
            <h5>{{"productDetail.more.label" | translate}}</h5>
            <div *ngIf="this.more.length === 0">
              <p class="pt-3 pb-3 none">{{"productDetail.more.text" | translate}}</p>
            </div>
            <div *ngIf="this.more.length > 0">
              <ng-container *ngFor="let m of more; let idx = index">
                <app-related *ngIf="idx <= 4" [product]="m"></app-related>
              </ng-container>
            </div>
          </div>


        </div>
        <hr>
        <div class="col-md-6 col-lg-12">
          <div class="borderOrange m-1 card">
            <h5>{{"productDetail.related.label" | translate}}</h5>
            <div *ngIf="this.related.length === 0">
              <p class="pt-3 pb-3 none">{{"productDetail.related.text" | translate}}</p>
            </div>
            <div *ngIf="this.related.length > 0">
              <ng-container *ngFor="let r of related; let idx = index">
                <app-related *ngIf="idx <= 4" [product]="r"></app-related>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row marginT">
    <div class="col-12">
      <nav class="text-center">
        <div class="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
          <a class="nav-item nav-link active" id="nav-home-tab" data-toggle="tab" href="#nav-home" role="tab"
            aria-controls="nav-home" aria-selected="true">{{"product.description" | translate}}</a>
          <a class="nav-item nav-link" id="nav-profile-tab" data-toggle="tab" href="#nav-profile" role="tab"
            aria-controls="nav-profile" aria-selected="false">{{"store.questions" | translate}}</a>
          <a class="nav-item nav-link" id="nav-contact-tab" data-toggle="tab" href="#nav-contact" role="tab"
            aria-controls="nav-contact" aria-selected="false">{{"product.policies" | translate}}</a>
        </div>
      </nav>
    </div>
    <div class="col-12 p-4">
      <div class="tab-content py-3 px-3 px-sm-0" id="nav-tabContent">
        <div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
          <p [innerHtml]="product?.description"></p>
        </div>
        <div class="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
          <app-comments [id]="product?._id"></app-comments>
        </div>
        <div class="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">
          <p [innerHtml]="product?.policies"></p>
          <p [innerHTML]="product?.policies" *ngIf="product?.policies == ''">
            This product has not expecific policies
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="row  mTop d-block  d-lg-none">
    <div class="col-md-12 col-lg-3">
      <div class="row">
        <div class="col-md-6 col-lg-12">
          <div class="borderOrange m-1 card">
            <h5>{{"productDetail.more.label" | translate}}</h5>
            <div *ngIf="this.more.length === 0">
              <p class="pt-3 pb-3 none">{{"productDetail.more.text" | translate}}</p>
            </div>
            <div *ngIf="this.more.length > 0">
              <ng-container *ngFor="let m of more">
                <app-related [product]="m"></app-related>
              </ng-container>
            </div>
          </div>


        </div>
        <hr>
        <div class="col-md-6 col-lg-12">
          <div class="borderOrange m-1 card">
            <h5>{{"productDetail.related.label" | translate}}</h5>
            <div *ngIf="this.related.length === 0">
              <p class="pt-3 pb-3 none">{{"productDetail.related.text" | translate}}</p>
            </div>
            <div *ngIf="this.related.length > 0">
              <ng-container *ngFor="let r of related">
                <app-related [product]="r"></app-related>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>