import { Injectable } from "@angular/core";
import { BaseService } from "./common/base.service";
import { LoadingService } from './loading.service';
import { HttpClient } from "@angular/common/http";
import { ErrorService } from "./common/error.service";
import { TokenService } from './token.service';
import { ToastService } from './common/toast.service';

@Injectable({
  providedIn: 'root'
})
export class LegalService extends BaseService {

  constructor(public http: HttpClient, public error: ErrorService, public token: TokenService, public toast: ToastService, public loadingService: LoadingService) {
    super(http, error, token, toast, loadingService);
    this.host = this.host + "settings/legal";
  }
}
