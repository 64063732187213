import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.scss']
})
export class ItemComponent implements OnInit {

  @Input() wishlist: any[];

  @Output() deletedItem: EventEmitter<string>;

  constructor() {
    this.deletedItem = new EventEmitter();
  }

  deleteItem = (id) => {
    this.deletedItem.emit(id);
  }

  ngOnInit(): void {
  }

}
