<div class="row">
  <div class="col">
    <select (change)="secondLevel($event.target.value)" class="form-control">
      <option value="null">{{"select" | translate}}</option>
      <option [value]="c._id" *ngFor="let c of categories">{{c.label}}</option>
    </select>
  </div>

  <div class="col" *ngIf="second && second?.length">
    <select (change)="thirdLevel($event.target.value, second)" class="form-control">
      <option value="null">{{"select" | translate}}</option>
      <option [value]="c._id" *ngFor="let c of second">{{c.label}}</option>
    </select>
  </div>

  <div class="col" *ngIf="third && third?.length">
    <select (change)="setThirdSelect($event.target.value, third)" class="form-control">
      <option value="null">{{"select" | translate}}</option>
      <option [value]="c._id" *ngFor="let c of third">{{c.label}}</option>
    </select>
  </div>
</div>

<!--ul>
  <ng-container
    *ngTemplateOutlet="recursiveListTmpl; context:{ list: tree }"
  ></ng-container>
</ul>


<ng-template #recursiveListTmpl let-list="list">
  <select *ngIf= "list.length > 0" (change) = "onChanges($event.target.value)" class="form-control">
    <option value="-1" *ngFor = "let c of list">Selecciona</option>
    <option [value]="c._id" *ngFor = "let c of list">{{c.label}}</option>
  </select>
  <div *ngFor="let item of list">
    <div *ngIf="item.subcategories.length > 0">
      <ng-container
        *ngTemplateOutlet="recursiveListTmpl; context:{ list: item.subcategories }"
      ></ng-container>
    </div>
  </div>
</ng-template-->