import { Component, OnInit } from '@angular/core';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { StoreService } from '../services/store.service';

@Component({
  selector: 'app-sellerlist',
  templateUrl: './sellerlist.component.html',
  styleUrls: ['./sellerlist.component.scss']
})
export class SellerlistComponent implements OnInit {

  sellers: any[] = [];
  faCaretDown = faCaretDown;

  page:number = 1; 
  pageSize:number = 1; 
  collectionSize = 1

  constructor(public storeService: StoreService) {

    this.getSellers(1);
  }

  getSellers(page:number){
    this.storeService.getStoresPaginated({ page: page, limit:20 }).subscribe(
      (data: any) => {
        this.sellers = data.docs;
        this.page = data.page;
        this.pageSize = data.limit;
        this.collectionSize = data.totalDocs;
      }
    )
  }

  ngOnInit(): void {
  }

}
