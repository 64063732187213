import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient, HttpEvent } from '@angular/common/http';
import { ErrorService } from './error.service';
import { TokenService } from '../token.service';
import { ToastService } from './toast.service';
import { LoadingService } from '../loading.service';


@Injectable({
  providedIn: 'root'
})
export class CategoryService extends BaseService {

  categories: any[] = [];

  constructor( public http: HttpClient,
               public error: ErrorService,
               public token: TokenService,
               public toast: ToastService,
               public loadingService: LoadingService ) {
    super(http, error, token, toast, loadingService);
    this.host = this.host + 'category';

    this.doGet(this.host).subscribe((data: any[]) => {
      this.categories = data;

    });


  }

  getCategories() {
    const url = this.host;
    return this.doGet(url);
  }



}
