import { Injectable } from "@angular/core";
//import { AngularFireMessaging } from '@angular/fire/compat/messaging'
import { element } from "protractor";
import { BehaviorSubject, Observable } from "rxjs";
import { mergeMap } from "rxjs/operators";
import { ToastService } from "../common/toast.service";
import { NotificationRecount } from "../models/notificationRecount";
import { NotificationEventCodes } from "../models/notificatonData";
import { NotificationService } from "../notification.service";
import { deleteToken, getMessaging, getToken, onMessage } from "firebase/messaging";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})
export class PushNotificationService {

  private notificationRecount: NotificationRecount = this.emptyRecount()

  private currentNotifications: any[] = [];

  private notificationList = new BehaviorSubject<NotificationRecount>(this.notificationRecount);
  public notifications$: Observable<any> = this.notificationList.asObservable();

  constructor(
    // private afMessaging: AngularFireMessaging,
    private toastService: ToastService,
    private notificationService: NotificationService
  ) {

  }

  requestPermission() {
    const messaging = getMessaging();
    return getToken(messaging, { vapidKey: environment.firebaseConfig.vapidKey })
  }

  listen() {
    const messaging = getMessaging();
    onMessage(messaging, (message:any) => {
      console.log('listen', message)
      this.toastService.notification(message.notification, message.data);
      this.getNotificationList();
    });
  }

  getToken() {
    const messaging = getMessaging();
    return getToken(messaging, { vapidKey: environment.firebaseConfig.vapidKey })
  }

  onMessage() {
    const messaging = getMessaging();
    onMessage(messaging, (message:any) => {
      console.log('asdfasdfas', message)
      this.toastService.notification(message.notification, message.data);
    })
  }

  deleteToken() {
    const messaging = getMessaging();
    return this.getToken().then(token => deleteToken(messaging))
  }

  getNotificationList() {
    this.notificationService.getNotifications().subscribe((data: any) => {

      this.notificationRecount = this.emptyRecount();
      let total = 0;
      this.currentNotifications = data;

      data.filter(element => !element.viewed && !element.isAdmin).forEach(element => {
        total++;
        let data = JSON.parse(element.data)
        if (element.notif_type === NotificationEventCodes.chatStore) {
          this.notificationRecount.store.total += 1;
          this.notificationRecount.store.messages = {
            total: (this.notificationRecount.store.messages.total + 1),
            list: { ...this.notificationRecount.store.messages.list, [data.data._id]: this.notificationRecount.store.messages?.list[data.data._id] ? this.notificationRecount.store.messages.list[data.data._id] += 1 : 1 }
          }
        }
        if (element.notif_type === NotificationEventCodes.newOrder || element.notif_type === NotificationEventCodes.updateOrder_by_user) {
          this.notificationRecount.store.total += 1
          this.notificationRecount.store.orders = {
            total: (this.notificationRecount.store.orders.total + 1),
            list: { ...this.notificationRecount.store.orders.list, [data.data._id]: this.notificationRecount.store.orders?.list[data.data._id] ? this.notificationRecount.store.orders.list[data.data._id] += 1 : 1 }
          }
        }
        if (element.notif_type === NotificationEventCodes.ticketUpdate_store) {
          this.notificationRecount.store.total += 1
          this.notificationRecount.store.support += 1
        }

        if (element.notif_type === NotificationEventCodes.chatUser) {
          this.notificationRecount.account.total += 1
          this.notificationRecount.account.messages = {
            total: (this.notificationRecount.store.messages.total + 1),
            list: { ...this.notificationRecount.store.messages.list, [data.data._id]: this.notificationRecount.store.messages?.list[data.data._id] ? this.notificationRecount.store.messages.list[data.data._id] += 1 : 1 }
          }
        }

        if (element.notif_type === NotificationEventCodes.ticketUpdate_user) {
          this.notificationRecount.account.total += 1
          this.notificationRecount.account.support += 1
        }

        if (element.notif_type === NotificationEventCodes.updateOrder_by_store) {
          this.notificationRecount.account.total += 1
          this.notificationRecount.account.purchases = {
            total: (this.notificationRecount.account.purchases.total + 1),
            list: { ...this.notificationRecount.account.purchases.list, [data.data._id]: this.notificationRecount.account.purchases?.list[data.data._id] ? this.notificationRecount.account.purchases.list[data.data._id] += 1 : 1 }
          }
        }

        if (element.notif_type === NotificationEventCodes.ticketNew) {
          this.notificationRecount.store.total += 1
          this.notificationRecount.store.support += 1
        }

        if (element.notif_type === NotificationEventCodes.product_ask) {
          this.notificationRecount.store.total += 1
          this.notificationRecount.store.questions = {
            total: (this.notificationRecount.store.questions.total + 1),
            list: { ...this.notificationRecount.store.questions.list, [data.data._id]: this.notificationRecount.store.questions?.list[data.data._id] ? this.notificationRecount.store.questions.list[data.data._id] += 1 : 1 }
          }
        }

      });
      this.notificationRecount.total = total;
      this.notificationList.next(this.notificationRecount);
    })
  }

  checkViewed(id: string, type: NotificationEventCodes) {
    let remove = []
    this.currentNotifications.filter(element => !element.viewed).forEach(element => {
      let data = JSON.parse(element.data)
      if (type === NotificationEventCodes.ticketUpdate_store && element.notif_type === type) {
        remove.push({ _id: element._id, viewed: true });
      }
      if (type === NotificationEventCodes.ticketUpdate_user && element.notif_type === type) {
        remove.push({ _id: element._id, viewed: true });
      }
      if (element.notif_type === type && data.data._id === id) {
        remove.push({ _id: element._id, viewed: true });
      }
    });

    if (remove.length === 0) return;
    this.setViewed(remove);
  }

  setViewed(remove: any[], position = 0) {
    this.notificationService.setViewed(remove[position]).subscribe((_v) => {
      if (position >= (remove.length - 1)) {
        this.getNotificationList()
        return;
      }
      this.setViewed(remove, position += 1);
    })

  }

  emptyRecount() {
    return {
      total: 0,
      account: {
        messages: {
          total: 0,
          list: {}
        },
        total: 0,
        support: 0,
        purchases: {
          total: 0,
          list: {}
        },
      },
      store: {
        messages: {
          total: 0,
          list: {}
        },
        total: 0,
        support: 0,
        orders: {
          total: 0,
          list: {}
        },
        questions: {
          total: 0,
          list: {}
        },
      }
    }
  }


}
