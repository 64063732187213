
  <div class="card-body">

    <img [src]="channel?.img">

    <div class="body-content">
      <span class="account-name">{{channel?.name}}</span>
      <p class="account-about">{{channel?.about}}</p>

    </div>
  </div>
