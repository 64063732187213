import { Injectable } from "@angular/core";
import { BaseService } from "./common/base.service";
import { LoadingService } from './loading.service';
import { HttpClient, HttpEvent, HttpHeaders, HttpRequest } from "@angular/common/http";
import { ErrorService } from "./common/error.service";
import { TokenService } from './token.service';
import { ToastService } from './common/toast.service';
import { Store } from "../models/store.model";
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class StoreService extends BaseService {

  constructor(public http: HttpClient, public error: ErrorService, public token: TokenService, public toast: ToastService, public loadingService: LoadingService) {
    super(http, error, token, toast, loadingService);
    this.host = this.host + "store";
  }

  createStore(logo: File, banner: File, data: Store): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();
    const headers = new HttpHeaders()
      .set("Authorization", this.tokenService.get());

    formData.append('data', JSON.stringify(data));

    if (logo) {
      formData.append('img', logo);
    }

    if (banner) {
      formData.append('banner', banner);
    }

    const req = new HttpRequest('POST', `${this.host}`, formData, {
      reportProgress: true,
      responseType: 'json',
      headers: headers,
    });

    return this.http.request(req);
  }

  getStores = () => {
    const url = this.host;
    return this.doGet(url);
  }

  getStoresPaginated = (page: any = null) => {
    const url = this.host;
    return this.doGet(url, true, this.setOptions(page));
  }

  getNewStores = () => {
    const url = this.host + '/new_sellers';
    return this.doGet(url);
  }

  getStore = (id: string) => {
    const url = this.host + '/' + id;
    return this.doGet(url);
  }

  getMyStore = () => {
    const url = this.host + '/me';
    return this.doGet(url);
  }

  updateMyStore = (data: any) => {
    const url = this.host;
    return this.doPut(url, data);
  }

  updateOrderStatus = (id: string, status: string) => {
    let url = this.host + '/checkout/' + id;

    let body = {
      status: status
    };

    return this.doPut(url, body);

  }

  public getStoreRate(id: string) {
    const url = this.host + '/rate/' + id;
    return this.doGet(url);
  }

  getCheckouts(filters = { page: 1 }) {
    const orderBy = JSON.stringify([{ field: 'createdAt', orderBy: "DESC" }])
    const url = this.host + '/checkout/?order=' + orderBy;
    return this.doGet(url, true, this.setOptions({ ...filters }));
  }

  setTracking = (id: string, tracking: string) => {
    let url = this.host + '/checkout/tracking/' + id;
    return this.doPut(url, { tracking });
  }

  updateStore(logo: File, banner: File, data: Store): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();
    const headers = new HttpHeaders()
      .set("Authorization", this.tokenService.get());

    formData.append('data', JSON.stringify(data));

    /* for (const property in data) {
       //console.log(`${property}: ${newData[property]}`);
       formData.append(`${property}`, `${data[property]}`);
     }*/

    if (logo) {
      formData.append('img', logo);
    }

    if (banner) {
      formData.append('banner', banner);
    }


    const req = new HttpRequest('POST', `${this.host + '/edit'}`, formData, {
      reportProgress: true,
      responseType: 'json',
      headers: headers,
    });

    return this.http.request(req);
  }

  getCheckoutDetails(id: string) {
    const url = this.host + `/checkout/details/${id}`
    return this.doGet(url, true);
  }

  setPaymethod = (data: any) => {
    const url = this.host + '/paymethod';
    return this.doPut(url, data);
  }

  getPaymethods() {
    const url = this.host + '/paymethod';
    return this.doGet(url, true);
  }

  getPublicPaymethods(id: string) {
    const url = this.host + '/paymethod/' + id;
    return this.doGet(url, true);
  }

  getStoreStats(startDate: string, endDate: string) {
    const url = this.host + `/stats?minDate=${startDate}&maxDate=${endDate}`
    return this.doGet(url, true);
  }


  closeAccount = () => {
    const url = this.host;
    return this.doDelete(url);
  }

}
