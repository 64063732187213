import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MenuComponent } from './components/menu/menu.component';
import { FooterComponent } from './components/footer/footer.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { SharedModule } from './shared/shared.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SearchComponent } from './components/menu/components/search/search.component';
import { NoLogedComponent } from './components/menu/components/no-loged/no-loged.component';
import { LanguageComponent } from './components/menu/components/language/language.component';
import { RecoveryComponent } from './recovery/recovery.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProductComponent } from './product/product.component';
import { RelatedComponent } from './product/components/related/related.component';
import { CommentsComponent } from './product/components/comments/comments.component';
import { HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AuthService } from './services/auth.service';
import { TokenService } from './services/token.service';
import { ErrorService } from './services/common/error.service';
import { PublicStoreService } from './services/publicstore.service';
import { LoadingService } from './services/loading.service';
import { WishlistService } from './services/wishlist.service';
import { ChatService } from './services/chat.service';
import { AuthGuard } from './services/guards/auth.guards';
import { JwtModuleOptions, JwtModule } from '@auth0/angular-jwt';
import { LoggedComponent } from './components/menu/components/logged/logged.component';
import { LoggedMblComponent } from './components/menu/components/logged-mbl/logged-mbl.component';
import { OAuthModule } from 'angular-oauth2-oidc';
import { ContactComponent } from './contact/contact.component';
import { CategorymenuComponent } from './components/categorymenu/categorymenu.component';
import { CategoryComponent } from './category/category.component';
import { SearchscreenComponent } from './searchscreen/searchscreen.component';
import { HeadComponent } from './searchscreen/components/head/head.component';
import { NgxPicaModule } from '@digitalascetic/ngx-pica';
import { FiltersComponent } from './searchscreen/components/filters/filters.component';
import { WishlistComponent } from './wishlist/wishlist.component';
import { ItemComponent } from './wishlist/item/item.component';
import { LegaltextComponent } from './components/legaltext/legaltext.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { CommonModule } from '@angular/common';
import { CategorylistComponent } from './components/categorylist/categorylist.component';
import { SafePipe } from './pipes/safeurl.pipe';
import { ItemCategoryComponent } from './category/item-category/item-category.component';
import { ListCategoriesComponent } from './category/list-categories/list-categories.component';
import { NewsListComponent } from './news-list/news-list.component';
import { MostviewlistComponent } from './mostviewlist/mostviewlist.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { CheckoutProductComponent } from './checkout/components/checkout-product/checkout-product.component';
import { CheckoutAddressComponent } from './checkout/components/checkout-address/checkout-address.component';
import { CheckoutPaymentComponent } from './checkout/components/checkout-payment/checkout-payment.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { SellerlistComponent } from './sellerlist/sellerlist.component';
import { NgxPayPalModule } from 'ngx-paypal';
import { NgxEchartsModule } from 'ngx-echarts';
import { NgxCaptchaModule } from 'ngx-captcha';
import { ContactsuccessComponent } from './contactsuccess/contactsuccess.component';
import { initializeApp } from "firebase/app";
import { environment } from 'src/environments/environment';
initializeApp(environment.firebaseConfig);

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export function tokenGetter() {
  return localStorage.getItem('access_token');
}

const JWT_Module_Options: JwtModuleOptions = {
  config: {
      tokenGetter: tokenGetter,
  }
};

const components = [
  AppComponent,
  MenuComponent,
  SearchComponent,
  NoLogedComponent,
  FooterComponent,
  HomeComponent,
  LoginComponent,
  RegisterComponent,
  RecoveryComponent,
  LanguageComponent,
  ProductComponent,
  RelatedComponent,
  CommentsComponent,
  LoggedComponent,
  ContactComponent,
  LoggedMblComponent,
  CategorymenuComponent,
  CategoryComponent,
  SearchComponent,
  SearchscreenComponent,
  HeadComponent,
  FiltersComponent,
  WishlistComponent,
  ItemComponent,
  LegaltextComponent,
  ItemCategoryComponent,
  ListCategoriesComponent,
  SafePipe,
  CategorylistComponent,
  NewsListComponent,
  MostviewlistComponent,
  CheckoutComponent,
  CheckoutProductComponent,
  SellerlistComponent,
]

@NgModule({
  declarations: [...components, CheckoutAddressComponent, CheckoutPaymentComponent, SellerlistComponent, ContactsuccessComponent ],
  imports: [
    BrowserModule,
    CommonModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    NgxPicaModule,
    TranslateModule.forRoot({
      defaultLanguage:'en',
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      },
  }),
  OAuthModule.forRoot(),
  JwtModule.forRoot(JWT_Module_Options),
  ToastrModule.forRoot(
    {
      positionClass: 'toast-bottom-right',
    }
  ),
  BsDatepickerModule.forRoot(),
  OAuthModule.forRoot(),
  NgbModule,
  FontAwesomeModule,
  FormsModule,
  NgxPayPalModule,
  ReactiveFormsModule,
  NgxEchartsModule.forRoot({
    /**
     * This will import all modules from echarts.
     * If you only need custom modules,
     * please refer to [Custom Build] section.
     */
    echarts: () => import('echarts'),
  }),
  NgxCaptchaModule
  ],
  providers: [
    AuthService,
     TokenService,
     ChatService,
     ErrorService,
     LoadingService,
     WishlistService,
     PublicStoreService,
     AuthGuard,
    
    ],
  bootstrap: [AppComponent]
})
export class AppModule { }
