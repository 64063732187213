import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class LoadingService {
  
  private loadingState = new BehaviorSubject(false);
  public loadingObservable = this.loadingState.asObservable();

  constructor() { }

  setLoading(state:boolean){
    setTimeout(()=> { this.loadingState.next(state);},0)
  }

}