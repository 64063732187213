<div class="form-group">
	<label [for]="key">{{label | translate}}</label>
	<input #inputfile  accept="image/x-png,image/gif,image/jpeg" type="file" id="file" (change)="handleFileInput($event.target.files)">
	<div class="row file-container" (click)="inputfile.click()">
		<div class="col-12 col-md-12">
			<div class="btn btn-primary filecontainer">
				<p>{{"select_pic" | translate}}</p>
			</div>
		</div>
		<div class="col-12 col-md-12">
			 {{file?.name}}
		</div>
	</div>
</div>