<div class="">
  <a class="dropdown-item" [routerLink]="['account']" (click) = "closeEmit.emit()">
    <fa-icon [icon]="iconProfile" class="mr-2 primary"></fa-icon>{{'account_menu.myaccount' |
    translate}}
    <span *ngIf="recount?.account?.total > 0"
      class="badge badge-pill badge-primary item-badge"><span class="num">{{recount?.account?.total}}</span></span>
  </a>
  <a class="dropdown-item store-item" (click)="store()">
    <fa-icon [icon]="iconStoreSession" class="mr-2 primary"></fa-icon> {{'account_menu.mystore' |
    translate}}
    <span *ngIf="recount?.store?.total > 0"
      class="badge badge-pill badge-primary item-badge"><span class="num">{{recount?.store?.total}}</span></span>
  </a>
  <a class="dropdown-item" [routerLink]="['wishlist']" (click) = "closeEmit.emit()">
    <fa-icon [icon]="faBookmark" class="mr-2 primary"></fa-icon> <span class="ml-1">Wishlist</span>
  </a>
  <a class="dropdown-item logout-item" (click)="logout()">
    <fa-icon [icon]="iconCloseSession" class="mr-2 primary logout"></fa-icon><span class="ml-1">{{'account_menu.logout' |
    translate}}</span>
  </a>
</div>