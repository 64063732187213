// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyCn55NUu1VWCOBdb3L4NtE_XyE0vywNnzk",
    authDomain: "vidsuq-pro.firebaseapp.com",
    projectId: "vidsuq-pro",
    storageBucket: "vidsuq-pro.appspot.com",
    messagingSenderId: "588828036234",
    appId: "1:588828036234:web:c3f0975d2e445c2214f173",
    measurementId: "G-HXV6QZR3HT",
    vapidKey:"BLg3qEGLCgtWvKuhqoQcoygwxvYDM3zav_LgpLJa3tZbg7LIfh30gFRYbIBCcEbpuWxbyA9R2gKph_n1jKR0w6Y"
  },
  platform_fee: 5,
  paypal_client_id_partner: "Aa0aM31q0O1vI4dOwYInYdQbwrPWtCQCDQ4OAKu2ucmPXNFUaWlOuixWvECZ_1D_edsz5knjcfagMnZs",
  paypal_secret_partner: "EPCFxqXnqmDvibIUiYtSYXLI5BpMUushEXNOOehLXbYSshzRZR07N9B6JRWPl4pl7Rd3bSoaKkei95f6",
  paypal_client_id: "ATIze4TcNR3ZF1IrHpW_eAac6BlFFhoLvl6pPXjW685BE3UkDtLYzYH3v-Fv-2E9719aEQJ1EqsZPJVC",
  paypal_secret: "ENH7BfeuIgy1XlSWPMo0K7_-3QNjMOoHacJWro6aEMbxBMPYXzGoMIrujKJE3e_J8yzccq0FB0X9KqEK",
  paypal_url: "https://api-m.sandbox.paypal.com",
  return_url: "https://visuq.com",
  appId: "1:588828036234:web:c3f0975d2e445c2214f173",
  measurementId: "G-WKJLTKJRSG",
  //host: 'http://localhost:8080',
  host: 'https://api.visuq.com',
  domain: 'https://www.visuq.com'
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
