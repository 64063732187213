<div class=language-container>
	<div [ngClass]="{'drop-mobile' : isMobile}" class="dropdown" id="dropdownMenuButton" data-toggle="dropdown"
		aria-haspopup="true" aria-expanded="false">
		<div [ngClass]="isMobile ? 'sel-mobile' : 'sel-web'">
			<h6 class="selected">{{selected.text}}</h6>
		</div>
		<div class="dropdown-menu text-center pt-2">
			<h6 *ngFor="let l of langs" class="dropdown-item" (click)="setSelected(l)">{{l.text}}</h6>
		</div>
	</div>
</div>