import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ContactComponent } from './contact/contact.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { ProductComponent } from './product/product.component';
import { RecoveryComponent } from './recovery/recovery.component';
import { RegisterComponent } from './register/register.component';
import { AuthGuard } from './services/guards/auth.guards';
import { CategoryComponent } from './category/category.component';
import { SearchscreenComponent } from './searchscreen/searchscreen.component';
import { WishlistComponent } from './wishlist/wishlist.component';
import { LegaltextComponent } from './components/legaltext/legaltext.component';
import { NewsListComponent } from './news-list/news-list.component';
import { MostviewlistComponent } from './mostviewlist/mostviewlist.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { SellerlistComponent } from './sellerlist/sellerlist.component';
import { ContactsuccessComponent } from './contactsuccess/contactsuccess.component';

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent },
  { path: 'login', component: LoginComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'recovery', component: RecoveryComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'wishlist', component: WishlistComponent },
  { path: 'checkout/:id', component: CheckoutComponent, canActivate: [AuthGuard] },
  { path: 'account', loadChildren: () => import('./account/account.module').then(mod => mod.AcountModule), canActivate: [AuthGuard] },
  { path: 'my-store', loadChildren: () => import('./store/store.module').then(mod => mod.StoreModule), canActivate: [AuthGuard] },
  { path: 'store', loadChildren: () => import('./publicstore/publicstore.module').then(mod => mod.PublicstoreModule) },
  { path: 'my-panel', loadChildren: () => import('./mystore/mystore.module').then(mod => mod.MystoreModule), canActivate: [AuthGuard] },
  { path: 'product/:id', component: ProductComponent },
  { path: 'search/:term', component: SearchscreenComponent },
  { path: 'category/:label', component: CategoryComponent },
  { path: 'mystore', loadChildren: () => import('./mystore/mystore.module').then(m => m.MystoreModule) },
  { path: 'mostviewlist', component: MostviewlistComponent },
  { path: 'newlist', component: NewsListComponent },
  { path: 'sellerlist', component: SellerlistComponent },
  { path: 'legal/:id', component: LegaltextComponent },
  { path: 'contact_success', component: ContactsuccessComponent },
  { path: '**', redirectTo: '', pathMatch: 'full' },
]

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
