<div class="container mt-5">
    <div class="row mt-5 mb-4">
        <div class="col mt-5">
            <h1>{{ 'contactUs' | translate }}</h1>
        </div>
    </div>

    <div class="row">
        <div class="col-12 mb-5 mt-2">
            <app-dynamicform [buttons]="buttonsData" [form]="formValue"></app-dynamicform>
        </div>
    </div>
</div>