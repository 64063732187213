import { Component, Input, OnInit } from '@angular/core';
import { ChatService } from 'src/app/services/chat.service';
import { ToastService } from 'src/app/services/common/toast.service';
import { NotificationEventCodes } from 'src/app/services/models/notificatonData';
import { PushNotificationService } from 'src/app/services/notifications/pushnotifications';

@Component({
  selector: 'app-mobile',
  templateUrl: './mobile.component.html',
  styleUrls: ['./mobile.component.scss']
})
export class MobileComponent implements OnInit {

  public show:boolean = true;

  @Input() data: any[];
  @Input() iAm: string;
  @Input() notificationList = {};

  contacts: any[] = [];
  messages: any[] = [];
  selectedContact: any;
  contactsType: string;

  constructor(private chatService: ChatService, private pushNotifications: PushNotificationService, private toastService: ToastService) { }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    this.getContacts();
  }

  getContacts() {
    this.contacts = this.data.map(elem => {
      let ni;

      if (typeof elem.store === 'object') {
        ni = { ...elem.store };
        this.contactsType = 'store';
      } else {
        ni = { ...elem.user };
        this.contactsType = 'user';
      }

      ni['last'] = elem.last;
      ni['chatId'] = elem._id;
      return ni;
    });


    if (this.contacts.length) {
      this.selectedContact = this.selectedContact ? this.selectedContact : this.contacts[0];
      this.getConversation(this.selectedContact.chatId);
    }
  }

  getConversation = (id: string) => {

    let contact = this.contacts.find(elem => elem.chatId == id);
    if (contact && id) {
      this.selectedContact = contact;
      this.chatService.getChatDetails(id).subscribe((data: any) => {
        this.messages = data;
        let type = this.iAm === 'store' ? NotificationEventCodes.chatStore : NotificationEventCodes.chatUser;
        this.pushNotifications.checkViewed(id, type);
      });
    }

  }

  writeMessage = (msg: string) => {

    let body = {
      msg: msg
    }


    if (this.contactsType == 'store') {
      body['store'] = this.selectedContact._id;
    } else {
      body['user'] = this.selectedContact._id;
    }

    this.chatService.sendMessage(body).subscribe((data: any) => {
      if (!this.selectedContact.chatId) {
        this.selectedContact.chatId = data._id;
        let idx = this.contacts.findIndex(elem => elem._id == this.selectedContact._id);

        if (idx > -1) this.contacts[idx].chatId = data._id;
      }
      setTimeout(() => { this.getConversation(this.selectedContact.chatId) }, 500);

    });
  }

  
  showChat(){
    this.show = !this.show
  }

}
