<div class="userChat mb-2" *ngFor="let c of contacts"
    [class]="((c.chatId === contactChat || c._id === contactChat) && !isMobile)? 'selected' : 'backWhite'">
    <a (click)="changeChat(c.chatId || {id: c._id, isNew: c.chatId ? false : true})">
        <span *ngIf="notificationList[c.chatId]"
            class="badge badge-pill badge-primary">{{notificationList[c.chatId]}}</span>
        <div class="row">
            <div class="col-3 col-md-3">
                <img class="avatar" [src]="c.img ? c.img : './assets/img/logo.png' ">
            </div>
            <div class="col-8 col-md-9">
                <div class="row">
                    <div class="col-12 header-container">
                        <div class="userName">
                            {{ c?.name }}
                        </div>

                    </div>
                    <div class="col-12">
                        <p class="msg">{{ c?.last?.msg?.substring(0, 25) }} {{ c?.last?.msg?.length > 25 ? '...' : '' }} 
                            <i class="time">{{ c?.last.createdAt | date: 'short' }}</i>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </a>
</div>