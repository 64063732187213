<div class="row mb-5" *ngFor="let item of wishlist">
	<div class="col-12 col-md-3">
		<div class="pic-cont">
			<a [routerLink]="['/product', item._id]">
				<div class="pic" [style.background-image]="'url(' + item?.imgs[0]  + ')'">
				</div>
			</a>

		</div>
	</div>
	<div class="col-12 col-md-6">
		<div class="row text-side">
			<div class="col-12">
				<a [routerLink]="['/product', item._id]">
					<label>
						{{ item.name.toUpperCase() }}
					</label>
				</a>
			</div>
			<div class="col-12">
				<p>
					{{ 'product.seller' | translate }} :
					<a [routerLink]="['/store/on-sale', item?.store?._id]" class="storelink">{{item.store.name}}</a>
				</p>
			</div>
			<div class="col-12">
				<p>
					<b [ngClass]="item.status === 'onSale' ? 'available' : 'sold'">{{ (item.status === 'onSale' ? 'store.on_sale' : 'soldOut') | translate }}</b>
				</p>
			</div>
		</div>
	</div>
	<div class="col-12 col-md-3">
		<div class="row text-position">
			<div class="col-12">
				<label>${{ item.price }}</label>
			</div>
			<div class="col-12 mt-4">
				<button type="button" [routerLink]="['/checkout', item._id]" class="btn btn-primary btn-lg btn-width">{{
					'product.buy' | translate }}</button>
			</div>
			<div class="col-12 mt-4">
				<button type="button" (click)="deleteItem( item._id )" class="btn btn-danger btn-lg mr-2 btn-width">{{
					'delete' |
					translate }}</button>
			</div>
		</div>
	</div>
	<div class="col-12 mt-4">
		<hr>
	</div>

</div>

<div class="container" *ngIf="wishlist.length == 0">
	<div class="row">
		<div class="col-12 text-center">
			<img src="./assets/img/orange.png" class="img-fluid image">
			<h5>
				{{'wishlist_no_content' | translate}}
			</h5>

		</div>
	</div>
</div>