import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Category } from 'src/app/models/category.model';


@Component({
  selector: 'menu-categories',
  templateUrl: './list-categories.component.html',
  styleUrls: ['./list-categories.component.scss']
})
export class ListCategoriesComponent implements OnInit {

  @Input() categories:Category[]=[]
  @Input() level:number=0
  @Input() callback:any = null;
  @Output() clickCategory: EventEmitter<Category>  = new EventEmitter();

  ngOnInit(): void { }
}
