import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { DragScrollComponent } from 'ngx-drag-scroll';

@Component({
  selector: 'app-store-list',
  templateUrl: './store-list.component.html',
  styleUrls: ['./store-list.component.scss']
})
export class StoreListComponent implements OnInit {
  @Input() data = null;
  @ViewChild('nav', { read: DragScrollComponent }) ds: DragScrollComponent;
  left = faAngleLeft;
  right = faAngleRight;
  @Input() isDragList = false;
  @Output() moreEmit = new EventEmitter();

  @Input() page: number = 1;
  @Input() pageSize: number = 1;
  @Input() collectionSize = 1

  constructor() { }

  ngOnInit(): void {
  }
  moveLeft() {
    this.ds.moveLeft();
  }

  moveRight() {
    this.ds.moveRight();
  }

  moveTo(index) {
    this.ds.moveTo(index);
  }

  ngAfterViewInit() {
    this.loadDrag();
  }

  loadDrag() {
    setTimeout(() => {
      if (!this.ds) return;
      this.ds.moveTo(1);
    }, 0);
  }



}
