import { Component, OnInit, Input, HostListener } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-categorymenu',
  templateUrl: './categorymenu.component.html',
  styleUrls: ['./categorymenu.component.scss']
})
export class CategorymenuComponent implements OnInit {

  resize = null;
  limitWidth = 768;
  limitItems = 6;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    event.target.innerWidth;
    this.resize = event.target.innerWidth;
    this.setLimit();
  }

  @Input() categories: any[];

  constructor(private router: Router) { }

  ngOnInit(): void {
    this.resize = window.innerWidth;
    this.setLimit();
  }

  goToCategory(label: string) {
    console.log(label);
    this.router.navigate(['/category', label]);
  }

  setLimit(){
    if(this.resize === null) return;

    this.limitItems = this.resize < this.limitWidth ? 4 : 6 
  }

}
