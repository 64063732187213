export enum NotificationEventCodes {
  newOrder = "newOrder",
  updateOrder_by_store = "updateOrder_by_store",
  updateOrder_by_user = "updateOrder_by_user",
  newMsg = "newMsg",
  chatUser = "chatUser",
  chatStore = "chatStore",
  ticketUpdate_store = "ticketUpdate_store",
  ticketUpdate_user = "ticketUpdate_user",
  ticketNew = "ticketNew",
  product_ask = "product_ask"
}

export declare namespace NotificationEventPayload {
  interface NewOrder {
    _id?: string,
    buyerName: string,
    productName: string,
    productSlug: string,
    productPrice: string,
  }

  interface NewMsg {
    _id: string,
    authorName: string,
    userName: string,
  }

  interface UpdateOrder {
    _id?: string,
    buyerName: string,
    productName: string,
    productSlug: string,
    productPrice: string,
  }
}

export interface notifBBDD {
  createdAt: string,
  data: string,
  notif_type: string,
  user: string,
  viewed: boolean,
  _id: string,
}

export declare type NotificationMessage = (
  {
    type: NotificationEventCodes.newOrder
  } & NotificationEventPayload.NewOrder) | ({
    type: NotificationEventCodes.newMsg
  } & NotificationEventPayload.NewMsg) | ({
    type: NotificationEventCodes.updateOrder_by_user
  } & NotificationEventPayload.UpdateOrder)