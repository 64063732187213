<div class="cards text-center">
  <a [routerLink] = "['/product']">
    <div class="pic" [style.background-image]="'url(' + url  + ')'"></div>
    <div class="contain">
      <div class="row">
        <div class="col-8 text-left">
          <h5><b>John Doe</b></h5>
        </div>
        <div class="col-4 text-right">
          <h5><b>90,50</b><br>€</h5>
        </div>
      </div>
      <div class="row posicion">
        <div class="col-12 text-left">
          <img class="avatar" src="https://picsum.photos/200">
          <label>Youtuber</label>
        </div>
      </div>
    </div>
  </a>
</div>