<div class="top"></div>
<div class="container">
  <div class="row">
    <div class="col mb-3">
      <h1>Checkout</h1>
    </div>
  </div>
  <app-checkout-product [product] = "product"></app-checkout-product>
  <div class="mb-3">
    <h3>{{'observations' | translate}}</h3>
    <textarea [(ngModel)] = "observations" class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
  </div>
  <div class="row">
    <div class="col-12 col-md-6">
      <app-checkout-address (addressChange)="addressId = $event"></app-checkout-address>
    </div>
    <div class="col-12 col-md-6">
    <fieldset [disabled]="!addressId">
      <app-checkout-payment  [product] = "product" #payments (endEmit) = "send($event)"></app-checkout-payment>
    </fieldset>
    </div>
  </div>
</div>
