import { Component, OnInit } from '@angular/core';
import { ProductsService } from '../services/products.service';
import { CategoryService } from '../services/common/category.service';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { Category } from '../models/category.model';


@Component({
  selector: 'app-news-list',
  templateUrl: './news-list.component.html',
  styleUrls: ['./news-list.component.scss']
})
export class NewsListComponent implements OnInit {

  products = null;
  allProducts: any[] = [];
  categories: any[] = [];
  category: string;
  faCaretDown = faCaretDown;

  page:number = 1; 
  pageSize:number = 1; 
  collectionSize = 1

  constructor(public productsService: ProductsService,
    public categoryService: CategoryService,
    public activatedRoute: ActivatedRoute,
    public router: Router
  ) {

    this.categoryService.getCategories().subscribe((data: any[]) => {
      this.categories = data;
    });

    this.getProducts(1);
  }

  getProducts(page:number){
    this.productsService.getNewProducts({ page: page, limit:20 }).subscribe(
      (data: any) => {
        this.products = data.docs;
        this.page = data.page;
        this.pageSize = data.limit;
        this.collectionSize = data.totalDocs;
      }
    )
  }

  ngOnInit(): void {
  }

  clickItem(cat: Category) {
    console.log(cat);
  }
}

