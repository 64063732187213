<div class="container-fluid bg-vidcraft">
    <div class=" d-none d-md-block ">
        <div class="row p-4">
            <div class="col-12 col-md-3 offset-md-1">
                <a [routerLink]="'/home'">
                    <img src="{{logo | assetsRoute:'img'}}" alt="logo">
                </a>
                <br>
                <span>
                    <a (click)="goToLink(rrss.twitter)">
                        <fa-icon [icon]="twitter" class="icon-size"></fa-icon>
                    </a>
                    <a (click)="goToLink(rrss.facebook)">
                        <fa-icon [icon]="facebook" class="icon-size"></fa-icon>
                    </a>
                    <a (click)="goToLink(rrss.instagram)">
                        <fa-icon [icon]="instagram" class="icon-size"></fa-icon>
                    </a>
                    <a (click)="goToLink(rrss.youtube)">
                        <fa-icon [icon]="youtube" class="icon-size"></fa-icon>
                    </a>
                </span>
            </div>

            <p class="copy">© 2023, VISUQ GmbH. All Rights Reserved. </p>

            <div class="col-12 col-md-4">
                <p><a [routerLink]="['/legal','cookies']"> {{legalData ? legalData['cookies']?.name : ''}}</a></p>
                <p><a [routerLink]="['/legal','legal']"> {{legalData ? legalData['legal']?.name : ''}}</a></p>
                <p><a [routerLink]="['/legal','privacy']"> {{legalData ? legalData['privacy']?.name : ''}}</a></p>
                <p><a [routerLink]="'/contact'"> Contact</a></p>
            </div>

            <div class="col-12 col-md-4">
                <p *ngFor="let l of legalText"><a [routerLink]="['/legal', l._id]"> {{l.name}}</a></p>
            </div>

        </div>
    </div>
    <div class="row p-4 d-md-none d-lg-none">
        <div class="col-12 col-md-6 text-center pb-2">
            <a [routerLink]="'/home'">
                <img src="{{logo | assetsRoute:'img'}}">
            </a>

            <br>
            <span class="pt-3">
                <a (click)="goToLink(rrss.twitter)">
                    <fa-icon [icon]="twitter" class="icon-size"></fa-icon>
                </a>
                <a (click)="goToLink(rrss.facebook)">
                    <fa-icon [icon]="facebook" class="icon-size"></fa-icon>
                </a>
                <a (click)="goToLink(rrss.instagram)">
                    <fa-icon [icon]="instagram" class="icon-size"></fa-icon>
                </a>
                <a (click)="goToLink(rrss.youtube)">
                    <fa-icon [icon]="youtube" class="icon-size"></fa-icon>
                </a>
                
            </span>

        </div>

        <p class="copy">© {{currentYear}}, VISUQ GmbH. All Rights Reserved. </p>

        <div class="col-12 col-md-6 text-center pb-2 pt-3">
            <p><a [routerLink]="['/legal','cookies']"> {{legalData ? legalData['cookies']?.name : ''}}</a></p>
            <p><a [routerLink]="['/legal','legal']"> {{legalData ? legalData['legal']?.name : ''}}</a></p>
            <p><a [routerLink]="['/legal','privacy']"> {{legalData ? legalData['privacy']?.name : ''}}</a></p>
            <p><a [routerLink]="'/contact'"> Contact</a></p>
        </div>

        <div class="col-12 col-md-6 text-center">
            <p *ngFor="let l of legalText"><a [routerLink]="['/legal', l._id]"> {{l.name}}</a></p>
        </div>

    </div>
</div>