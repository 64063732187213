<div class="container minH">
	<div class="row">
		<div class="col-12 text-center mt-5 mb-5">
			<h1>{{'sign_up' | translate}}</h1>
			<img src="./assets/img/logo.png">
		</div>
	</div>
	<div class="row mb-5">
		<div class="col-12">
			<div class="row">
				<div class="col-12">
					<div class="form-container">
						<app-dynamicform [buttons]="buttonsData" [form]="formValue"></app-dynamicform>
					</div>
				</div>
				<div class="col-12 text-center">
					<p><a [routerLink]="'../login'">{{'got_account' | translate}}</a></p>
				</div>
			</div>

		</div>

	</div>
</div>

<ng-template #emailSended let-modal>
	<div class="modal-header">
			<h4 class="modal-title" id="modal-title">{{'activate_email_title' | translate}}</h4>
			<button type="button" class="close" aria-describedby="modal-title" (click)="modal.dismiss('Cross click')">
					<span aria-hidden="true">&times;</span>
			</button>
	</div>
	<div class="modal-body">
			<p [innerHTML]="'activate_email_msg' | translate"></p>
	</div>

</ng-template>