import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { CategoryService } from '../../../services/common/category.service';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { NgbPanelChangeEvent } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss']
})
export class FiltersComponent implements OnInit {

  categories = [];
  faCaretDown = faCaretDown;
  selectedCategories = [];

  @Output() selectedCategory: EventEmitter<string[]>;
  @Output() selectedOrder: EventEmitter<number>;

  constructor(public categoryService: CategoryService) {

    this.selectedCategory = new EventEmitter();
    this.selectedOrder = new EventEmitter();

    this.categoryService.getCategories().subscribe((data: any[]) => {

      this.categories = data;

    });
  }

  ngOnInit(): void {
  }

  onOrderChange(value) {
    this.selectedOrder.emit(value);
  }


  checkSelectedCategories(categoryId: string) {
    if ((<HTMLInputElement>document.getElementById(categoryId)).checked === true) {
        if(!this.selectedCategories.includes(categoryId)){
          this.selectedCategories.push(categoryId);
        }
        this.selectSubcategories(categoryId, this.categories);
    }
    else if ((<HTMLInputElement>document.getElementById(categoryId)).checked === false) {
        const indexx = this.selectedCategories.indexOf(categoryId);
        if(indexx >= 0){
          this.selectedCategories.splice(indexx, 1);
        }
        this.unSelectSubcategories(categoryId, this.categories);
    }

    this.selectedCategory.emit(this.selectedCategories);

  }

  /*
   * Función que se ejecuta al desplegar un elemento del menú. Si el elemento está
   * marcado, se marcarán también los elementos hijos
   */
  afterChange($event: NgbPanelChangeEvent){

    const catId = $event.panelId.substr(2);

    const cat = this.categories.find(elem => elem._id === catId);

    if(cat){
      setTimeout(() => {
          if(cat.hasOwnProperty('subcategories')){
            if((<HTMLInputElement>document.getElementById(catId)).checked === true){
              cat.subcategories.forEach(elem => {
                (<HTMLInputElement>document.getElementById(elem._id)).checked = true;
              });
            } else {
              cat.subcategories.forEach(elem => {
                (<HTMLInputElement>document.getElementById(elem._id)).checked = false;
              });
            }
          }
      }, 300);
    }

  }

  selectSubcategories(categoryId: string, categories) {
    let cat = categories.find(elem => elem._id === categoryId);

    if(cat){
      if(cat.hasOwnProperty('subcategories')){
        cat.subcategories.forEach(elem =>{
          let ncb = (<HTMLInputElement>document.getElementById(elem._id));
          if(ncb){
            (<HTMLInputElement>document.getElementById(elem._id)).checked = true;
          }

          if(!this.selectedCategories.includes(elem._id)){
            this.selectedCategories.push(elem._id);
          }

          if(elem.hasOwnProperty('subcategories')){
            this.selectSubcategories(elem._id, cat.subcategories);
          }
        });
      }

    } else { // si no la ha encontrado, es que es una subcategoría
      categories.forEach(elem => {
        if(elem.hasOwnProperty('subcategories')){
          cat = elem.subcategories.find(itm => itm._id === categoryId);
          if(cat){
            if(cat.hasOwnProperty('subcategories')){
              cat.subcategories.forEach(elem =>{
                const ncb = (<HTMLInputElement>document.getElementById(elem._id));

                if(ncb){
                  (<HTMLInputElement>document.getElementById(elem._id)).checked = true;
                }

                if(!this.selectedCategories.includes(elem._id)){
                  this.selectedCategories.push(elem._id);
                }
              });
            }

          }

        }
      });
    }


  }

  unSelectSubcategories(categoryId: string, categories) {
    let cat = categories.find(elem => elem._id === categoryId);
    if(cat){
      if(cat.hasOwnProperty('subcategories')){
        cat.subcategories.forEach(elem =>{
          (<HTMLInputElement>document.getElementById(elem._id)).checked = false;
          const indexx = this.selectedCategories.indexOf(elem._id);
          if(indexx >= 0){
            this.selectedCategories.splice(indexx, 1);
          }

          if(elem.hasOwnProperty('subcategories')){
            this.unSelectSubcategories(elem._id, cat.subcategories);
          }
        });
      }
    } else {
      categories.forEach(elem => {
        if(elem.hasOwnProperty('subcategories')){
          cat = elem.subcategories.find(itm => itm._id === categoryId);
          if(cat){
            if(cat.hasOwnProperty('subcategories')){
              cat.subcategories.forEach(elem =>{
                let ncb = (<HTMLInputElement>document.getElementById(elem._id));
                if(ncb){
                  (<HTMLInputElement>document.getElementById(elem._id)).checked = false;
                }
                const indexx = this.selectedCategories.indexOf(elem._id);
                if(indexx >= 0){
                  this.selectedCategories.splice(indexx, 1);
                }
              });
            }

          }

        }
      });

    }

  }

}
