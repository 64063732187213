<div class="d-none d-md-block">
    <div class="row">
        <div class="col-12">
            <label>{{ 'orderBy' | translate }}:</label>
        </div>
        <div class="col-12">
            <select class="form-control" aria-label="Default select example" (change)="onOrderChange($event.target.value)">
              <option selected>{{ 'selectOrder' | translate }}</option>
              <option value="1">{{ 'lowerPrice' | translate }}</option>
              <option value="2">{{ 'higherPrice' | translate }}</option>
              <option value="3">{{ 'alphabetical' | translate }}</option>
            </select>
        </div>
    </div>

    <div class="row mt-5">
        <div class="col-12">
            <label>{{ 'product.category' | translate }}:</label>
        </div>

        <div class="col-12">
            <ng-container *ngTemplateOutlet="recursiveListTmpl; context:{ list: categories }"></ng-container>

            <ng-template #recursiveListTmpl let-list="list">
                <div *ngFor="let item of list">
                    <div *ngIf="!item.subcategories.length > 0" class="nosubcat">
                        <label><input type="checkbox" value="{{ item._id }}" id="{{ item._id }}" (change)="checkSelectedCategories(item._id)"> {{ item.label }}</label>
                    </div>
                    <ngb-accordion *ngIf="item.subcategories.length > 0" [closeOthers]="true" [destroyOnHide]='false' (panelChange)="afterChange($event)">
                        <ngb-panel id="p-{{ item._id }}">
                            <ng-template ngbPanelHeader>
                                <div class="d-flex justify-content-between">
                                    <span class="">
                                    <label><input class="mr-1" type="checkbox" value="{{ item._id }}" id="{{ item._id }}" (change)="checkSelectedCategories(item._id)"> {{ item.label }}</label>
                                  </span>
                                    <button ngbPanelToggle class="btn p-0">
                                    <fa-icon [icon]="faCaretDown"></fa-icon>
                                  </button>
                                </div>

                            </ng-template>
                            <ng-template ngbPanelContent>
                                <ng-container *ngTemplateOutlet="recursiveListTmpl; context:{ list: item.subcategories }">
                                </ng-container>
                            </ng-template>
                        </ngb-panel>
                    </ngb-accordion>
                </div>

            </ng-template>
        </div>


    </div>
</div>

<div class="d-block d-md-none">
    <div class="row">
        <div class="col-12 text-center">
            <label>{{ 'orderBy' | translate }}:</label>
        </div>
        <div class="col-12 text-center">
            <select class="form-control mb-3" aria-label="Default select example">
              <option selected>{{ 'selectOrder' | translate }}</option>
              <option value="1">{{ 'lowerPrice' | translate }}</option>
              <option value="2">{{ 'higherPrice' | translate }}</option>
              <option value="3">{{ 'alphabetical' | translate }}</option>
            </select>
        </div>
    </div>
</div>
