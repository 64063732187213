import { Injectable } from '@angular/core';
import { BaseService } from './common/base.service';
import { LoadingService } from './loading.service';
import { HttpClient, HttpEvent } from '@angular/common/http';
import { ErrorService } from './common/error.service';
import { TokenService } from './token.service';
import { ToastService } from './common/toast.service';
import { Observable } from 'rxjs';
import { Token } from '../models/token.model';

@Injectable({
  providedIn: 'root'
})
export class WishlistService extends BaseService {

  constructor(public http: HttpClient,
              public error: ErrorService,
              public token: TokenService,
              public toast: ToastService,
              public loadingService: LoadingService) {
    super(http, error, token, toast, loadingService);
    this.host = this.host + 'whislist';
  }

  getWishlist = () => {
    const url = this.host;
    return this.doGet(url);
  }

  updateWishlist = (data) => {
    const url = this.host;
    return this.doPut(url, data);
  }

  addToWishlist = (id: any, toastText: string) => {
    const url = this.host;
    return this.doPost(url, id, false, toastText);
  }

  deleteFromWishlist = (id: string, toastText: string) => {
    const url = this.host + '/' + id;
    return this.doDelete(url, false, toastText);
  }


}
