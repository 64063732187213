import { Injectable } from '@angular/core';
import { BaseService } from './common/base.service';
import { HttpClient, HttpEvent, HttpHeaders, HttpRequest } from '@angular/common/http';
import { ErrorService } from './common/error.service';
import { TokenService } from './token.service';
import { ToastService } from './common/toast.service';
import { LoadingService } from './loading.service';
import { Observable } from 'rxjs';
import { Product } from '../models/product.model';

@Injectable({
    providedIn: 'root'
})
export class ProductsService extends BaseService {

    products = [];

    constructor(public http: HttpClient,
        public error: ErrorService,
        public token: TokenService,
        public toast: ToastService,
        public loadingService: LoadingService) {

        super(http, error, token, toast, loadingService);
        this.host = this.host + 'product';
    }

    public getProducts = () => {
        const url = this.host;
        return this.doGet(url);
    }

    public getRelated(id: string) {
        const url = this.host + '/related/' + id;
        return this.doGet(url);
    }

    public getMore(id: string) {
        const url = this.host + '/more/' + id;
        return this.doGet(url);
    }


    public getNewProducts = (page: any = null) => {
        const url = this.host + '/new_products';
        return this.doGet(url, true, this.setOptions(page));
    }

    public getMostViewedProducts = (page: any = null) => {
        const url = this.host + '/most_viewed';
        return this.doGet(url, true, this.setOptions(page));
    }

    public getProductsBySlug = (slug: string) => {
        const url = this.host + '/c/' + slug;
        return this.doGet(url);
    }

    public getProduct = (id: string) => {
        const url = this.host + '/' + id;
        return this.doGet(url);
    }

    public getProductStoreOnSale(id: string) {
        const url = this.host + '/store/' + id + '?status=onSale';
        return this.doGet(url);
    }

    public getMyProducts(id: string, status = 'all') {
        const url = this.host + '/store/' + id + '?status=' + status;
        return this.doGet(url);
    }

    public changeStatus(id: string, status: string) {
        const url = this.host + '/status';
        return this.doPut(url, { _id: id, status: status },)
    }

    public addRate(item: { _id: string, rate: number }) {
        const url = this.host + '/rate';
        return this.doPost(url, item);
    }

    public getProductRate(id: string) {
        const url = this.host + '/rate/' + id;
        return this.doGet(url);
    }


    createProduct(files: File[], data: Product): Observable<HttpEvent<any>> {
        const formData: FormData = new FormData();
        const headers = new HttpHeaders()
            .set("Authorization", this.tokenService.get());

        formData.append('data', JSON.stringify(data));

        files.forEach((file) => {
            formData.append('file', file);
        })

        const url = data._id ? '/edit' : '';

        const req = new HttpRequest('POST', `${this.host + url}`, formData, {
            reportProgress: true,
            responseType: 'json',
            headers: headers,
        });

        return this.http.request(req);
    }

    public searchProducts = (term: string, count?: number, page?: number) => {
        let url = this.host + '/search?q=' + term;

        if (count) {
            url += '&count=' + count;
        }

        if (page) {
            url += '&page=' + page;
        }

        return this.doGet(url);
    }

}
