import { Component, OnInit, OnDestroy } from '@angular/core';
import { ProductsService } from '../services/products.service';
import { CategoryService } from '../services/common/category.service';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { Category } from '../models/category.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss']
})
export class CategoryComponent implements OnInit {

  products = null;
  allProducts: any[] = [];
  categories: any[] = [];
  category: string;
  faCaretDown = faCaretDown;

  constructor(public productsService: ProductsService,
    public categoryService: CategoryService,
    public activatedRoute: ActivatedRoute,
    public router: Router
  ) {


    this.activatedRoute.params.subscribe(params => {
      if (!params.hasOwnProperty('label')) return;

      let slug = params['label'];

      this.getProductsBySlug(slug);
      this.getCategories(slug);

    });
  }

  getProductsBySlug(slug){
    this.productsService.getProductsBySlug(slug).subscribe((data: any[]) => {
      this.products = data;
    });
  }

  getCategories(slug){
    this.categoryService.getCategories().subscribe((data: any[]) => {
      this.categories = data.filter(cat => cat.slug === slug);
    })
  }

  ngOnInit(): void {
  }

  selectCategory = (cat: Category) => {
    //this.router.navigate(['/category', cat.slug]);
    this.getProductsBySlug(cat.slug);
  }

  ngOnDestroy(): void {

  }


}
