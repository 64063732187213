<app-head></app-head>

<div class="container-fluid mt-5 mb-4">
	<div class="row mb-3">
		<div class="col-12">
			<button (click)="changeSelected(0)" type="button"
				class=" {{ selected === 0 ? 'btn btn-outline-primary' : 'btn btn-outline-default'}}">{{"product.products" | translate}}</button>
			<button (click)="changeSelected(1)" type="button"
				class="{{ selected === 0 ? 'btn btn-outline-default' : 'btn btn-outline-primary'}}">{{"store.stores" | translate}}</button>
		</div>

	</div>
	<div class="row">
		<div class="col-md-3 col-lg-3 col-12" *ngIf="selected === 0 ">
			<app-filters (selectedCategory)="setSelectedCategories($event)" (selectedOrder)="orderBy($event)">
			</app-filters>
		</div>
		<div class="col-12 col-md-9 col-lg-9" *ngIf="selected === 0">
			<div *ngIf="products?.length === 0">
				<div class="element">
					<h3 class="item">{{ '_cat' | translate }}</h3>
				</div>
			</div>
			<div *ngIf="products?.length > 0">
				<app-cardlist [data]="products"></app-cardlist>
			</div>
		</div>
		<div class="col-12" *ngIf="selected === 1">
			<div *ngIf="stores?.length === 0">
				<div class="element">
					<h3 class="item">{{ 'no_stores' | translate }}</h3>
				</div>
			</div>
			<div *ngIf="stores?.length > 0">
				<app-store-list [data]="stores"></app-store-list>
			</div>
		</div>
	</div>
</div>