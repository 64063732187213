<div class="row" #inputMessage>
    <div class="col-12" *ngFor="let msg of msgs">

        <div *ngIf="msg.author !== iAm" class="incoming_msg m-2">
            <div class="received_msg tri-right left-top">
                <div class="received_withd_msg">
                    <p>{{msg.msg}}</p>
                    <span class="time_date">
                      {{msg.createdAt | date: 'short' }}
                    </span>
                </div>
            </div>
        </div>

        <div *ngIf="msg.author === iAm" class="outgoing_msg m-2">
            <div class="sent_msg tri-left right-top">
                <p>{{msg.msg}}</p>
                <span class="time_date text-right">
                  {{msg.createdAt | date: 'short' }}
                </span>
            </div>
        </div>
    </div>

</div>