<div class="container" *ngIf="isDragList">
  <div class="row" *ngIf="!data">
    <div class="col-4">
      <app-cardskeletton></app-cardskeletton>
    </div>
    <div class="col-4">
      <app-cardskeletton></app-cardskeletton>
    </div>
    <div class="col-4">
      <app-cardskeletton></app-cardskeletton>
    </div>
  </div>

  <div class="row" *ngIf="data">
    <div class="col-12">
      <ng-container *ngIf="data.length > 0">
        <drag-scroll #nav>
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 text-center card-container" drag-scroll-item
            *ngFor="let product of data">
            <div class="cards text-center">
              <a [routerLink]="['/product', product._id]">
                <div class="pic"
                  [style.background-image]="product.imgs.length > 0 ? 'url(' +product?.imgs[0] + ')' : 'url('+('orange.png' | assetsRoute:'img')+')' ">
                </div>
              </a>
              <div class="contain">
                <div class="row">
                  <div class="col-12 text-left product-title">
                    <a [routerLink]="['/product', product._id]">
                      <h3><b>{{ product?.name }}</b></h3>
                    </a>
                  </div>

                  <div class="col-12 text-left store-content">
                    <a [routerLink]="['/store/on-sale', product?.store?._id]" class="hoverOn">
                      <img class="avatar"
                        [src]="product?.store?.img ? product?.store?.img : 'orangeSoft.png' | assetsRoute:'img'+'' ">

                      <label class="store-title dragtitle">{{product?.store?.name}}</label>
                    </a>
                  </div>
                </div>
                <div class="row">
                  <div class="row price">
                    <h3><b>{{ product?.price }}{{product?.store?.currency | CurrencyPipe}}</b></h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div (click)="moreEmit.emit()" class="see_more">
            <div>{{'view_more' | translate}}</div>
          </div>
          <div class="movebtn prev-btn" (click)="moveLeft()">
            <fa-icon [icon]="left" size="3x"></fa-icon>
          </div>
          <div class="movebtn next-btn" (click)="moveRight()">
            <fa-icon [icon]="right" size="3x"></fa-icon>
          </div>
        </drag-scroll>
      </ng-container>

      <ng-container *ngIf="data.length === 0">
        <h5>
          {{'no_products' | translate}}
        </h5>
      </ng-container>

    </div>
  </div>
</div>

<div class="container-fluid" *ngIf="!isDragList && data">
  <div class="row">
    <div class="col-12">
      <div *ngIf="pageSize > 1" class="text-center">
        <ngb-pagination (pageChange)="changepageEmit.emit($event)" [(page)]="page" [pageSize]="pageSize"
          [collectionSize]="collectionSize" [boundaryLinks]="true" [maxSize]="8"></ngb-pagination>
      </div>
      <div class="row" *ngIf="!data">
        <div class="col-4 ">
          <app-cardskeletton></app-cardskeletton>
        </div>
        <div class="col-4">
          <app-cardskeletton></app-cardskeletton>
        </div>
        <div class="col-4">
          <app-cardskeletton></app-cardskeletton>
        </div>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="data">
    <div *ngIf="myVideos" class="col-12 col-sm-6 col-lg-4 col-xl-3 text-center card-container">
      <div class="cards add-card">

        <a (click)="clearProduct()" title="{{ 'add' | translate }}">
          <div class="add-content">
            <fa-icon [icon]="plus" class="fa-4x"></fa-icon>
            {{'add_product' | translate}}
          </div>
        </a>

      </div>
    </div>

    <div class="col-12 col-sm-6 col-lg-4 col-xl-3 text-center card-container" *ngFor="let product of data">
      <div class="cards">
        <div class="card-container">
          <div *ngIf="hasOptions" class="icons-options">
            <div (click)="deleteEmit.emit(product)">
              <fa-icon ngbTooltip="{{ 'delete' | translate }}" class="trash" [icon]="trash"></fa-icon>
            </div>
            <div (click)="editEmit.emit(product)">
              <fa-icon ngbTooltip="{{ 'edit' | translate }}" class="pencil" [icon]="pencil"></fa-icon>
            </div>
            <div (click)="archiveEmit.emit(product)">
              <fa-icon [class]="product.status === 'hidden' ? 'red-color' : 'green-color'" [icon]="eye" placement="top"
                ngbTooltip="{{ product.status | translate }}"></fa-icon>
            </div>

            <div *ngIf="product?.status === 'sold'" (click)="availableAgainEmitt.emit(product)">
              <fa-icon [class]="product.status === 'hidden' ? 'red-color' : 'green-color'" [icon]="iconStoreSession"
                placement="top" ngbTooltip="{{ product.status | translate }}"></fa-icon>
            </div>
          </div>
          <a [routerLink]="['/product', product._id]">
            <div class="pic"
              [style.background-image]="product.imgs.length > 0 ? 'url(' +product?.imgs[0] + ')' : 'url('+('orange.png' | assetsRoute:'img')+')' ">
            </div>
          </a>
          <div class="contain">
            <div class="row">
              <div class="col-12 text-left product-title mb-1">
                <a [routerLink]="['/product', product._id]">
                  <h3><b>{{ product?.name }}</b></h3>
                </a>
              </div>

              <div class="col-12 text-left store-content">
                <a [routerLink]="['/store/on-sale', product?.store?._id]" class="hoverOn">
                  <img class="avatar"
                    [src]="product?.store?.img ? product?.store?.img : 'orangeSoft.png' | assetsRoute:'img'+'' ">

                  <label class="store-title">{{product?.store?.name}}</label>
                </a>
              </div>
              <div *ngIf="hasOptions" class="col-6 text-left pt-2">
                <h6 class="light"><i>{{ product?.status | translate }}</i></h6>
              </div>
              <div [class]="hasOptions ? 'col-6' : 'col-12'" class="text-right">
                <h3><b>{{ product?.price }}{{product?.store?.currency | CurrencyPipe}}</b></h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="pageSize > 1">
    <ngb-pagination (pageChange)="changepageEmit.emit($event)" [(page)]="page" [pageSize]="pageSize"
      [collectionSize]="collectionSize" [boundaryLinks]="true" [maxSize]="8"></ngb-pagination>
  </div>
</div>