import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { LoadingService } from './services/loading.service';
import { PushNotificationService } from './services/notifications/pushnotifications';
import { TokenService } from './services/token.service';
import { AuthService } from './services/auth.service';
import { BehaviorSubject } from 'rxjs';
import { LocalstorageService } from './utils/LocalStorage';
import { isPlatformBrowser } from '@angular/common';
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  loading = false;
  showCookies = false;
  static isBrowser = new BehaviorSubject<boolean>(null);

  constructor(
    loadingService: LoadingService, 
    private pushNotificationService: PushNotificationService, 
    private tokenService:TokenService, 
    private authService:AuthService,
    private localStorage:LocalstorageService,
    @Inject(PLATFORM_ID) private platformId: any) {

    loadingService.loadingObservable.subscribe(loading => {
      this.loading = loading
    })

    if(this.tokenService.isAuthenticated()) {
      this.pushNotificationService.getNotificationList();
      this.pushNotificationService.requestPermission().then(deviceToken => {
        console.log('******', deviceToken)
        this.authService.saveDeviceToken(deviceToken).subscribe(data => {
          this.pushNotificationService.listen();
          this.pushNotificationService.onMessage();
        })
      })
    }
    AppComponent.isBrowser.next(isPlatformBrowser(platformId));
    loadingService.loadingObservable.subscribe(loading => {
      this.loading = loading;
    });
    
    this.showCookies = this.localStorage.getItem('cookies_consent') === null || !!JSON.parse(this.localStorage.getItem('cookies_consent'));
  }
  ngOnInit(): void {
  }

  acept(){
    this.showCookies = false;
    this.localStorage.setItem('cookies_consent', JSON.stringify(false))
  }
}
