import { Component, OnInit, OnChanges, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-messagelist',
  templateUrl: './messagelist.component.html',
  styleUrls: ['./messagelist.component.scss']
})
export class MessagelistComponent implements OnInit, OnChanges {

  @Input() contacts: any[];
  @Input() notificationList = {};
  @Output() selectedChat: EventEmitter<string | { id: string, isNew: boolean }>;
  @Input() isMobile = false;

  contactChat: string;

  constructor() {
    this.selectedChat = new EventEmitter();
  }

  changeChat = (id: string | { id: string, isNew: boolean }) => {
    this.contactChat = typeof id === 'string' ? id as string : (id as { id: string, isNew: boolean }).id ;
    this.selectedChat.emit(id);
  }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    if (this.contacts.length && !this.contactChat) this.contactChat = this.contacts[0].chatId;
  }

}
