<div class="global-container container">
  <div>
    <h3>{{'storelist' | translate}}</h3>
  
  </div>
  <div class="row">
    <div class="col-12">
      <app-store-list (changepageEmit)="getSellers($event)" [data]="sellers" [page]="page" [pageSize]="pageSize"
        [collectionSize]="collectionSize"></app-store-list>
    </div>
  </div>
</div>