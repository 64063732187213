<div class="row ">

</div>

<div class="card bordeOrange">
    <div (click) = "showContactsChange.emit(true)" class="col-12 small" *ngIf="!support && isMobile">
        <fa-icon [icon]="back" class="fa-2x"></fa-icon>
    </div>
    <div #scrollMe class="card-body list-messages">
        <app-msg [id]="id" [msgs]="msgs" [iAm]="iAm"></app-msg>

    </div>
    <div class="card-footer text-muted">
        <div class="row">
            <input class="col-8 col-lg-11 col-md-10" type="text" placeholder="{{this.translate.instant('write_message')}}" [(ngModel)]="msg">
            <button (click)="sendMsg()" type="button" class="btn btn-link col-4 col-lg-1 col-md-2 text-center send">
				<fa-icon [icon]="send" class="fa-2x"></fa-icon>
			</button>
        </div>
    </div>