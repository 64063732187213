<div class="top"></div>
<div class="container mb-4">
    <div class="row">
        <div class="col-12">
            <h1>Wishlist</h1>
        </div>
        <div class="col-10 col-md-4">
            <hr>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-12">
            <app-item (deletedItem)="removeItem($event)" [wishlist]="wishlist"></app-item>
        </div>
    </div>
</div>
