import { ThisReceiver } from '@angular/compiler';
import { Component, Input, OnInit, Output,EventEmitter } from '@angular/core';

@Component({
  selector: 'app-fileinput',
  templateUrl: './fileinput.component.html',
  styleUrls: ['./fileinput.component.scss']
})
export class FileinputComponent implements OnInit {
  file: File = null;
  @Input() label:string = "";
  @Input() key:string = "";
  @Input() callback:any = null;
  @Output() emitFile:EventEmitter<File> = new EventEmitter<File>();

  constructor() { }

  handleFileInput(files: FileList) {
    this.file = files.item(0);
    if(this.callback){
      this.callback(this.file);
    }
  }

  uploadFileToActivity() {
  }

  ngOnInit(): void {
  }

}
