<div class="row">
  <div class="col-12 mb-3">
    <h3>
      {{'address' | translate}}
    </h3>
  </div>
  <div class="col-12 col-md-12">
    <ul class="box">
      <li class="address_title">
        <h3 class="page-subheading">{{'delivery_address' | translate}}</h3>
      </li>
      <div class="err" *ngIf = "!address">{{'no_selected_address' | translate}}</div>
      <li>{{address?.address_1}}</li>
      <li>{{address?.address_2}}</li>
      <li>{{address?.phone}}</li>
      <li>{{address?.country}}</li>
      <li>{{address?.state}}</li>
      <li>{{address?.city}}</li>
      <li>{{address?.zipcode}}</li>
      <li class="text-right">
        <a class="button button-small btn btn-primary" (click)=open(addressList) title="Actualizar">
          {{'change_label' | translate}}<i class="far fa-folder-open right" aria-hidden="true"></i></a>
      </li>
    </ul>
  </div>
</div>

<ng-template #addressList let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-title">{{ 'address_list' | translate}}</h4>
    <button type="button" class="close" aria-describedby="modal-title" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-6" *ngFor="let address of addresses">
        <!-- <app-address></app-address> -->
        <div class="card">
          <div>
            <div class="card-body">
              <div class="text-center">
                <div (click)="changeStatus(address._id)" class="containerCheck" [ngClass]="{'active': address.active}">
                  <div *ngIf="address.active">
                    <fa-icon [icon]="check"></fa-icon>
                  </div>
                </div>
              </div>
              <div>
                {{ address.name }}
              </div>
              <div>
                {{ address.address_1 }}
              </div>
              <div>
                {{ address.city }}, {{ address.cdiv }}
              </div>
              <div>
                {{ address.country }}
              </div>

              <div>
                {{ address.zipcode }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>