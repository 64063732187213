import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  private curToken: any = null;
  private watchIsLoged = new Subject<boolean>();
  public isLoged = this.watchIsLoged.asObservable();

  constructor(public jwtHelper: JwtHelperService) { }

  public isAuthenticated(): boolean {
    let token = localStorage.getItem('token');
    if (!token || token == null || token == '-1') {
      token = this.curToken;
      if (this.curToken == null) {
        this.watchIsLoged.next(false)
        return false;
      }
      this.watchIsLoged.next(true);
    }
    let expired = this.jwtHelper.isTokenExpired(token); 
    this.watchIsLoged.next(!expired);
    return !expired;
  }

  public get() {
    return localStorage.getItem('token');
  }

  public save(token: any) {
    localStorage.setItem('token', token);
    this.curToken = token;
    this.watchIsLoged.next(true)
  }

  public logout() {
    localStorage.removeItem('token');
    this.curToken = null;
    this.watchIsLoged.next(false);
  }

  public currentUserValue(): any {
    let token = localStorage.getItem('token');
    if (!token || token == null) {
      token = this.curToken;
    }
    return this.jwtHelper.decodeToken(token);
  }

  public isAdmin(): boolean {

    let token = localStorage.getItem('token');
    if (!token || token == null) {
      return false;
    }

    const decoded = this.jwtHelper.decodeToken(token);

    return (decoded.role == 'admin');
  }

}
