import { Component, OnInit, OnDestroy } from '@angular/core';
import { CategoryService } from '../services/common/category.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { ProductsService } from '../services/products.service';
import { Store } from '../models/store.model';


@Component({
  selector: 'app-searchscreen',
  templateUrl: './searchscreen.component.html',
  styleUrls: ['./searchscreen.component.scss']
})
export class SearchscreenComponent implements OnInit {

  term: string;
  products = null;
  allProducts = [];
  categories: any[] = [];
  selectedCategories: any[] = [];
  stores: Store[] = [];
  selected = 0;

  private subscribeProducts: Subscription;
  private subscribeCategories: Subscription;

  constructor(public productsService: ProductsService,
    public categoryService: CategoryService,
    public activatedRoute: ActivatedRoute) {

    this.activatedRoute.params.subscribe(params => {

      this.term = params.term.trim();

      this.subscribeProducts = this.productsService.searchProducts(this.term).subscribe((data) => {
        this.allProducts = data['products']['docs'];
        this.stores = data['stores']['docs'];
        this.products = this.allProducts;
      });

    });

    this.subscribeCategories = this.categoryService.getCategories().subscribe((data: any[]) => {
      this.categories = data;
    });


  }

  ngOnInit(): void {

  }

  setSelectedCategories(data) {
    this.selectedCategories = data;

    console.log(this.selectedCategories);
    this.products = [];

    this.allProducts.forEach(elem => {
      if (this.selectedCategories.includes(elem.category)) {
        this.products.push(elem);
      }
    });

    if (!this.selectedCategories.length) {
      this.products = this.allProducts;
    }

  }

  orderBy(value) {
    switch (value) {
      case '1':
        this.products.sort(this.orderPriceMinMax);
        break;
      case '2':
        this.products.sort(this.orderPriceMaxMin);
        break;
      case '3':
        this.products.sort(this.orderAlphabetical);
        break;
      default:
        this.products.sort(this.orderAlphabetical);
    }
  }

  orderPriceMinMax(a, b) {
    if (a.price < b.price) {
      return -1;
    }
    if (a.price > b.price) {
      return 1;
    }
    return 0;
  }

  orderPriceMaxMin(a, b) {
    if (a.price > b.price) {
      return -1;
    }
    if (a.price < b.price) {
      return 1;
    }
    return 0;
  }

  orderAlphabetical(a, b) {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  }

  ngOnDestroy(): void {
    this.subscribeProducts.unsubscribe();
    this.subscribeCategories.unsubscribe();
  }

  changeSelected(n){
    this.selected = n;
  }


}
