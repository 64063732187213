import { Component, Input, OnInit, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { IPayPalConfig } from 'ngx-paypal';
import { Payment, PaymentStoreBD } from 'src/app/models/payments.model';
import { Product } from 'src/app/models/product.model';
import { CartService } from 'src/app/services/cart.service';
import { PaypalService } from 'src/app/services/paypal.service';
import { StoreService } from 'src/app/services/store.service';
import { environment } from 'src/environments/environment';
import { getCurrencyAcronym } from 'src/app/utils/utils';
import { faUniversity } from '@fortawesome/free-solid-svg-icons';
import { faPaypal, faBitcoin } from '@fortawesome/free-brands-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

declare let paypal: any;
@Component({
  selector: 'app-checkout-payment',
  templateUrl: './checkout-payment.component.html',
  styleUrls: ['./checkout-payment.component.scss']
})
export class CheckoutPaymentComponent implements OnInit, AfterViewInit {

  payments: Payment[] = null;
  value = '';
  merchantId = '';
  payPalConfig: IPayPalConfig;
  @Input() product: Product = null;
  @Input() payment: Payment = null;
  @Output() endEmit = new EventEmitter<string>();
  @Input() storeId = '';
  paymentsDict = {};
  paypalemail = '';
  bank = faUniversity;
  paypal = faPaypal;
  bitcoin = faBitcoin;

  paymentId = '';

  constructor(private cartService: CartService, private storeService: StoreService, private pService: PaypalService, private modalService:NgbModal) {

  }
  ngAfterViewInit(): void {

  }

  ngOnInit(): void {
  }

  createPaypal = () => {
    this.pService.addPaypalPaymentScript(this.merchantId).then((data) => {
      this.paypalBtn();
    }).catch((err) => {
      console.error('Error', err);
    });
  }

  paypalBtn() {
    paypal.Buttons({
      style: {
        shape: 'rect',
        color: 'gold',
        layout: 'horizontal',
        label: 'pay',

      },
      createOrder: (data, actions) => {
        const currency = getCurrencyAcronym(this.product?.store?.currency);
        const productPrice = parseFloat(this.product.price).toFixed(2);
        const deliveryPrice = parseFloat(this.product.delivery_price || '0').toFixed(2);
        const totalPrice = parseFloat(parseFloat(productPrice) + parseFloat(deliveryPrice) + '').toFixed(2);
        const fee = parseFloat(((parseFloat(totalPrice) * environment.platform_fee) / 100) + '').toFixed(2);

        return actions.order.create({
          purchase_units: [{
            amount: {
              currency_code: currency,
              value: totalPrice,
              breakdown: {
                item_total: {
                  currency_code: currency,
                  value: totalPrice
                }
              }
            },
            items: [
              {
                name: this.product.name,
                description: this.product.description, /* Item details will also be in the completed paypal.com transaction view */
                unit_amount: {
                  currency_code: currency,
                  value: totalPrice
                },
                quantity: "1"
              },
            ],
            payee: {
              email_address: this.paypalemail //seller addres
            },
            payment_instruction: {
              disbursement_mode: "INSTANT",
              platform_fees: [{
                amount: {
                  currency_code: currency,
                  value: fee
                }
              }]
            }
          }]
        });
      },

      onApprove: (data, actions) => {
        return actions.order.capture().then((orderData) => {
          //console.log('Capture result', orderData, JSON.stringify(orderData, null, 2));
          //var transaction = orderData.purchase_units[0].payments.captures[0];
          //alert('Transaction ' + transaction.status + ': ' + transaction.id + '\n\nSee console for all available details');
          //this.pay(this.paymentsDict['paypal']._id)
        });
      }
    }).render('#paypal-button-container');

  }

  getStorePayments(storeId: string) {
    let hasPaypal = false;
    this.storeService.getPublicPaymethods(storeId).subscribe((payments: PaymentStoreBD[]) => {
      payments.forEach((element) => {
        if (element.paymethod.code === 'paypal') {
          this.paypalemail = element.email;
          this.merchantId = element.value;
          hasPaypal = (!!this.paypalemail && !!this.merchantId);
        }
        this.paymentsDict[element.paymethod.code] = element;
      })

      //if (hasPaypal) this.createPaypal();
    })
  }

  openConfirm(content, payment) {
    this.paymentId = payment;
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' });
  }



  pay() {
    this.endEmit.emit(this.paymentId);
  }
}
