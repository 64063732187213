import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-togglemenu',
  templateUrl: './togglemenu.component.html',
  styleUrls: ['./togglemenu.component.scss']
})
export class TogglemenuComponent implements OnInit {

  @Input() data:{title:string,href:string}[] = [];
  selected:number = 0;

  constructor(private router:Router) { }

  ngOnInit(): void {
  }

  goTo(route:string){
    //this.router.navigate([route]);
  }

}
