import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { CategoryService } from 'src/app/services/common/category.service';

@Component({
  selector: 'app-categorylist',
  templateUrl: './categorylist.component.html',
  styleUrls: ['./categorylist.component.scss']
})
export class CategorylistComponent implements OnInit {
  list:any[] = [];
  url:string = '';
  @Output() actionEmit = new EventEmitter();

  constructor(private categoryService:CategoryService, private router:Router) { }

  ngOnInit(): void {
    this.categoryService.list().subscribe(
      (isOk:any[])=>{
        this.list = isOk;
      }
    )
  }

  goToCategory(c, $event){
    this.url= c.slug+'/'+this.url
    setTimeout(()=>{
      $event.stopPropagation();
      if(!this.url) return;
      this.router.navigate(['/category/'+this.url]);
      this.url = '';
      this.actionEmit.emit();
    }, 10)
  }
}
