<div class="container">
	<div class="row">
		<div class="col-12 text-center mt-5 mb-2">
			<h1>{{'recovery' | translate}}</h1>
			<img src="./assets/img/logo.png">
		</div>
	</div>

	<div class="row">
		<div class="col-12 col-md-8 offset-md-2 pt-5 text-center">
			<app-dynamicform [buttons]="buttonsData" [form]="formValue"></app-dynamicform>

			<div *ngIf="msg" class="alert alert-success" role="alert">
				{{msg}}
			</div>
		</div>
	</div>
</div>