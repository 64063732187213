<div (click) = "taginput.focus()" class="container">
  <div class="row parent-container">
    <div class="tags-container" *ngFor="let t of tagList; let i = index">
      <span (click) ="delete(i)" class="badge badge-pill badge-primary">{{t}} 
        <fa-icon [icon]="faTimes"></fa-icon>
      </span>
    </div>
    <input #typeInput id="tagInput" [disabled]="tagList.length >= 5" #taginput [style.width.mm] = "(tagValue.length * 3)" (keydown) = "onPress($event)" (keyup)="onUp($event)" [(ngModel)] = "tagValue" type="text" />
  </div>
  <div class="row autocomplete-container">
    <ul *ngIf="recomendedList.length" class="list-autocomplete">
      <li (click) ="addTag(r)" *ngFor="let r of recomendedList"  [innerHtml]="r | SelectWordPipe: tagValue"></li>
    </ul>
  </div>
</div>
