import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { HttpClientModule } from '@angular/common/http';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TogglemenuComponent } from './components/togglemenu/togglemenu.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HeaderComponent } from './components/header/header.component';
import { DynamicformComponent } from './components/dynamicform/dynamicform.component';
import { FileinputComponent } from './components/dynamicform/components/fileinput/fileinput.component';
import { CardsComponent } from './components/cardlist/components/cards/cards.component';
import { CardlistComponent } from './components/cardlist/cardlist.component';
import { ChatComponent } from './components/chat/chat.component';
import { MessagelistComponent } from './components/chat/components/messagelist/messagelist.component';
import { MsgComponent } from './components/chat/components/msg/msg.component';
import { MobileComponent } from './components/chat/components/mobile/mobile.component';
import { MessagepanelComponent } from './components/chat/components/messagepanel/messagepanel.component';
import { SupportComponent } from './components/chat/components/support/support.component';
import { RouterModule } from '@angular/router';
import { StoreListComponent } from './components/store-list/store-list.component';
import { UploadimagesComponent } from './components/uploadimages/uploadimages.component';
import { AssetsRoutePipe } from '../pipes/assets-route.pipe';
import { CategoryselectComponent } from './components/categoryselect/categoryselect.component';
import { GalleryComponent } from './components/gallery/gallery.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TaginputComponent } from './components/dynamicform/components/taginput/taginput.component';
import { SelectWordPipe } from './pipes/selectword.pipe';
import { DragScrollModule } from 'ngx-drag-scroll';
import { SwipelistComponent } from './components/swipelist/swipelist.component';
import { PaginateComponent } from './components/paginate/paginate.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { CardskelettonComponent } from './components/cardlist/components/cardskeletton/cardskeletton.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { RateComponent } from './components/rate/rate.component';
import { NgxCaptchaModule } from 'ngx-captcha';
import { NgxUsefulSwiperModule } from 'ngx-useful-swiper';
import { ProfileHeaderComponent } from './components/profile-header/profile-header.component';
import { CurrencyPipe } from './pipes/currency.pipe';
import { ChannelItemComponent } from './components/channel-item/channel-item.component';

const components = [
  TogglemenuComponent,
  HeaderComponent,
  DynamicformComponent,
  FileinputComponent,
  CardsComponent,
  CardlistComponent,
  ChatComponent,
  MessagelistComponent,
  MsgComponent,
  MobileComponent,
  MessagepanelComponent,
  SupportComponent,
  StoreListComponent,
  UploadimagesComponent,
  AssetsRoutePipe,
  CategoryselectComponent,
  GalleryComponent,
  TaginputComponent,
  SwipelistComponent,
  PaginateComponent,
  RateComponent,
  ProfileHeaderComponent,
  ChannelItemComponent
];

const pipes = [
  SelectWordPipe,
  CurrencyPipe
]
@NgModule({
  declarations: [...components, ...pipes, SwipelistComponent, CardskelettonComponent ],
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    TranslateModule,
    FormsModule,
    FontAwesomeModule,
    ReactiveFormsModule,
    NgbModule,
    DragScrollModule,
    RouterModule,
    NgxSkeletonLoaderModule,
    BsDatepickerModule,
    NgxCaptchaModule,
    NgxUsefulSwiperModule
  ],
  exports: [FontAwesomeModule, components,NgxUsefulSwiperModule, pipes]
})
export class SharedModule { }
