import { Component, OnInit, OnDestroy } from '@angular/core';
import { ProductsService } from '../services/products.service';
import { CategoryService } from '../services/common/category.service';
import { StoreService } from '../services/store.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { BannerService } from '../services/banner.service';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  img: string = null;
  title = '';
  subtitle = '';
  newProducts = null;
  mostViewedProducts = null;
  categories: any[] = [];
  newStores: any[] = [];

  constructor(private productsService: ProductsService,
    private categoryService: CategoryService,
    private storeService: StoreService,
    private bannerService: BannerService,
    private router: Router) {

    this.productsService.getNewProducts().subscribe((data: any) => {
      this.newProducts = data.docs;
    });

    this.productsService.getMostViewedProducts().subscribe((data: any) => {
      this.mostViewedProducts = data.docs;
    });

    this.categoryService.getCategories().subscribe((data: any[]) => {
      this.categories = data.filter(cat => cat.visible);
    });

    this.storeService.getNewStores().subscribe((data: any[]) => {
      this.newStores = data;
    });

    this.bannerService.getBanner('home').subscribe((data: { title: string, img: string, subtitle: string }) => {
      const {title, subtitle, img} = data;
      this.title = title;
      this.img = img;
      this.subtitle = subtitle
    })

  }

  moreNew() {
    this.router.navigate(['newlist'])
  }

  moreMost() {
    this.router.navigate(['mostviewlist'])
  }

  moreStores() {
    this.router.navigate(['sellerlist'])
  }

  ngOnInit(): void {

  }

  ngOnDestroy(): void {
  }

}
