import { Component, OnInit, OnDestroy } from '@angular/core';
import { WishlistService } from '../services/wishlist.service';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-wishlist',
  templateUrl: './wishlist.component.html',
  styleUrls: ['./wishlist.component.scss']
})
export class WishlistComponent implements OnInit {

  wishlist: any[] = [];

  private subscribeWishlist: Subscription;
  private subscribeWishlistRemove: Subscription;

  constructor(public wishlistService: WishlistService, private translate: TranslateService) {
    this.subscribeWishlist = this.wishlistService.getWishlist().subscribe((data: any[]) => {
      this.wishlist = data;
    });
  }

  ngOnInit(): void {
  }

  removeItem = (id: string) => {
    this.subscribeWishlistRemove = this.wishlistService.deleteFromWishlist(id, this.translate.instant("product.removed_from_wishlist")).subscribe(res =>{
      const indexx = this.wishlist.findIndex(elem => elem._id === id);
      if(indexx > -1){
        this.wishlist.splice(indexx, 1);
      }
    });
  }

  ngOnDestroy(): void {
    this.subscribeWishlist.unsubscribe();
    if(this.subscribeWishlistRemove) this.subscribeWishlistRemove.unsubscribe();
  }

}
