<div class="menu-container">
    <div class="left col-2 col-sm-4 col-md-4  mt-2">
        <button #leftbtn class="navbar-toggler" type="button" (click)="openLeft()">
            <img src="./../../../assets/icons/bars.png" class="logo" alt="menu">
        </button>
        <div class="d-none d-sm-block">
            <a [routerLink]="'/home'" class="title">
                <!--img src="{{logo | assetsRoute:'img'}}" height="50px"-->
                <b>VISUQ</b>
            </a>
        </div>
    </div>
    <div class="col-8 col-sm-4 col-md-3 mt-3 mb-2">
        <div class="">
            <app-search></app-search>
        </div>
    </div>
    <div class="d-none d-md-block col-4 col-sm-4 col-md-5  ">
        <app-logged [recount]="notification$ | async" *ngIf="isLoged"
            [userName]="me.userName" [img]="me.img">
        </app-logged>
        <app-no-loged *ngIf="!isLoged"></app-no-loged>
        <app-language class="lang-web" (selectedEmit)=changelang($event)></app-language>
    </div>
    <div class="col-1 col-sm-3 col-md-4 text-right d-md-none navbar-toggler user-toggle-btn">
        <button #rightbtn (click)="openRight()" class="navbar-toggler" type="button">
            <fa-icon [icon]="faUser"></fa-icon>
            <div *ngIf="notifs?.total > 0" (click)="openRight()" class="notif">
                <span>{{notifs.total}}</span>
            </div>
        </button>
    </div>
</div>

<div #leftNav id="sidebar-left">
    <div class="cont" (click)="closeLeft()">
        <fa-icon [icon]="times"></fa-icon>
    </div>
    <div class="head" (click)="closeLeft()">
        <a [routerLink]="'/home'">
            <img src="./../../../assets/img/orange.png">
        </a>

        <h5>Categories</h5>
    </div>
    <div>
        <app-categorylist (actionEmit)="closeLeft()"></app-categorylist>
    </div>
</div>

<div #rightNav id="sidebar-right">
    <div class="container">
        <div (click)="closeRight()">
            <fa-icon [icon]="times"></fa-icon>
        </div>
        <div class="list">
            <app-logged-mbl (closeEmit)="closeRight()" [recount]="notification$ | async" *ngIf="isLoged"></app-logged-mbl>
            <app-no-loged [isMobile]="true" *ngIf="!isLoged" (closeEmit)="closeRight()"></app-no-loged>
            <app-language [isMobile]="true" class="lang-mbl" (selectedEmit)=changelang($event)></app-language>
        </div>

    </div>
</div>