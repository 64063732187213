import { Component, Input, OnInit } from '@angular/core';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';
import { HeaderModel } from 'src/app/models/header.model';
import { StoreService } from 'src/app/services/store.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit {

  @Input() public data:HeaderModel;
  @Input() haveParalax = true;
  @Input() rate = 0;
  star  = faStar;

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  goToStore = () => {
    this.router.navigate(['/store', this.data.idStore]);
  }

}
