import { Component, Input, OnInit, OnDestroy, ElementRef, ViewChild, Renderer2 } from '@angular/core';
import { faBookmark } from '@fortawesome/free-regular-svg-icons';
import { faFacebook, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faBookmark as solidBookmark, faShareAlt } from '@fortawesome/free-solid-svg-icons';
import { ActivatedRoute } from '@angular/router';
import { ProductsService } from '../services/products.service';
import { WishlistService } from '../services/wishlist.service';
import { TokenService } from '../services/token.service';
import { ImageSnippet } from '../models/ImageSnippet';
import { Product } from '../models/product.model';
import { HeaderModel } from '../models/header.model';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { StoreService } from '../services/store.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit {

  @ViewChild('iframe') iframe: ElementRef;

  images: ImageSnippet[] = [];
  showShare = false;

  isLogged: boolean = false;

  wishlist = faBookmark;
  solidWishList = solidBookmark;
  facebook = faFacebook;
  twitter = faTwitter;

  share = faShareAlt;

  myWishlist: any[] = [];

  isInWishlist = false;

  related: Product[] = []
  more: Product[] = []

  public data: HeaderModel = {
    backgroundimg: ''
  };

  product: Product;

  weightUnit = '';
  dimensionsUnit = '';
  rate = 0;

  domain = environment.domain;

  isIgVideo = false;

  private subscribeProducts: Subscription;
  private subscribeWishlist: Subscription;
  private subscribeWishlistAdd: Subscription;
  private subscribeWishlistRemove: Subscription;

  constructor(private activatedRoute: ActivatedRoute,
    private productsService: ProductsService,
    private wishlistService: WishlistService,
    private tokenService: TokenService,
    private storeService: StoreService,
    private translate: TranslateService,
    private renderer: Renderer2

  ) {
    this.activatedRoute.params.subscribe(params => {
      // coger el producto:
      this.subscribeProducts = this.productsService.getProduct(params['id']).subscribe((res: Product) => {
        this.product = res;
        this.getRelated();
        this.getMore();
        this.isIgVideo = !this.isnotIncludes(this.product.youtube, 'https://www.instagram.com')
        console.log(this.product)
        this.images = res.imgs.map(image => {
          return { src: image, file: null }
        })

        if (this.product.store) {
          this.data.backgroundimg = this.product.store?.banner;
          this.data.idStore = this.product.store._id;
          this.data.title = this.product.store.name;
          this.data.img = this.product.store?.img;
          this.storeService.getStoreRate(this.data.idStore).subscribe((rate: any) => {
            this.rate = rate.avg
          })
        }

        this.isLogged = this.tokenService.isAuthenticated();

        if (this.isLogged) {
          this.subscribeWishlist = this.wishlistService.getWishlist().subscribe((data: any[]) => {
            this.myWishlist = data;
            const pf = this.myWishlist.find(elem => elem._id === this.product._id);
            if (pf) {
              this.isInWishlist = true;
            }

          });
        }

      });
    });

  }

  isnotIncludes(string:any, substring:any) {
    return string.toLowerCase().indexOf(substring.toLowerCase())=== -1;
  }

  iframeLoad(){

  }

  ngOnInit(): void {

  }

  openShare(open: boolean) {
    setTimeout(() => this.showShare = open, 250)

  }

  getRelated() {
    this.productsService.getRelated(this.product._id).subscribe((data: Product[]) => {
      this.related = data;
    })
  }

  getMore() {
    this.productsService.getMore(this.product._id).subscribe((data: Product[]) => {
      this.more = data;
    });
  }

  addToWishlist = (id: string) => {
    const p = {
      id: id
    };

    this.subscribeWishlistAdd = this.wishlistService.addToWishlist(p, this.translate.instant("product.added_to_wishlist")).subscribe(res => {
      this.isInWishlist = true;
    });
  }

  removeFromWishlist = (id: string) => {
    this.subscribeWishlistRemove = this.wishlistService.deleteFromWishlist(id, this.translate.instant("product.removed_from_wishlist")).subscribe(res => {
      this.isInWishlist = false;
    });
  }

  ngOnDestroy(): void {
    this.subscribeProducts.unsubscribe();
    if (this.subscribeWishlist) this.subscribeWishlist.unsubscribe();
    if (this.subscribeWishlistAdd) this.subscribeWishlistAdd.unsubscribe();
    if (this.subscribeWishlistRemove) this.subscribeWishlistRemove.unsubscribe();
  }

  resizeIframe() {
 
    setTimeout(() => {
      console.log(this.iframe);
      /*const parentElement = this.iframe.nativeElement;
      const firstChild = parentElement.children[0];
      const frame = document.querySelector('iframe').contents
        console.log('***************', frame)
        const height = this.iframe.nativeElement.offsetHeight;
        const scrollHeight = this.iframe
        console.log(height);
        console.log(scrollHeight.nativeElement.contentWindow.document.body);
        //this.renderer.setStyle(this.iframe.nativeElement, 'height', `${900}px`);*/
    },1000)

  }

}
