import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { DragScrollComponent } from 'ngx-drag-scroll';
import { faAngleLeft, faAngleRight, faBoxOpen, faBox, faPencilAlt, faTrash, faEye, faPlus, faStore } from '@fortawesome/free-solid-svg-icons';
import { ProductFacadeService } from 'src/app/mystore/pages/my-videos/components/create-products/product.facade';
import { PaymenthodsService } from 'src/app/services/paymenthods.service';
import { StoreService } from 'src/app/services/store.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-cardlist',
  templateUrl: './cardlist.component.html',
  styleUrls: ['./cardlist.component.scss']
})
export class CardlistComponent implements OnInit {

  @Input() data = null;
  @Input() isDragList = false;
  @Input() hasOptions = false;
  @Input() myVideos = false;

  @Input() page: number = 1;
  @Input() pageSize: number = 1;
  @Input() collectionSize = 1

  @Output() moreEmit = new EventEmitter();
  @Output() changepageEmit = new EventEmitter<number>();
  @Output() deleteEmit = new EventEmitter();
  @Output() editEmit = new EventEmitter();
  @Output() archiveEmit = new EventEmitter();
  @Output() moreEmitt = new EventEmitter();
  @Output() noMethodsEmitt = new EventEmitter();
  @Output() availableAgainEmitt = new EventEmitter();

  @ViewChild('nav', { read: DragScrollComponent }) ds: DragScrollComponent;
  left = faAngleLeft;
  right = faAngleRight;
  pencil = faPencilAlt;
  boxOpen = faBoxOpen;
  box = faBox;
  trash = faTrash;
  eye = faEye;
  plus = faPlus;
  iconStoreSession = faStore;


  moveLeft() {
    this.ds.moveLeft();
  }

  moveRight() {
    this.ds.moveRight();
  }

  moveTo(index) {
    this.ds.moveTo(index);
  }

  ngAfterViewInit() {
    this.loadDrag();
  }

  loadDrag() {
    if (!this.ds) return;
    setTimeout(() => {
      this.ds.moveTo(1);
    }, 0);
  }

  clearProduct() {

    this.storeService.getPaymethods().subscribe((data: any) => {
      if (data.length === 0) {
        this.noMethodsEmitt.emit();
        return;
      }

      const hasTrue = data.find(value => value?.status);

      if(!hasTrue){
        this.noMethodsEmitt.emit();
        return;
      }

      this.productFacade.clear();
      this.router.navigate(['/my-panel/myVideos/create']);
    });


  }

  constructor(private productFacade: ProductFacadeService, private storeService: StoreService, private router: Router) { }

  ngOnInit(): void {

  }

}

