import { Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../services/auth.service';
import { DynamicFormModel } from '../shared/components/dynamicform/models/dynamicform.model';

@Component({
  selector: 'app-recovery',
  templateUrl: './recovery.component.html',
  styleUrls: ['./recovery.component.scss']
})
export class RecoveryComponent implements OnInit {

  msg = "";

  public buttonsData: any = {
    parentClass: '',
    buttons: [
      {
        hasDisabled : true,
        cssContainer: "",
        cssInput: "btn btn-primary text-white",
        title: this.translateService.instant('send'),
        callback: (values) => {
          this.recovery(values);
        }
      }
    ]
  }

  public formValue: DynamicFormModel[] = [
    {
      parentClass: 'row',
      inputs: [
        {
          classFormGroup: 'col-12',
          classInput: '',
          key: 'email',
          type: 'email',
          placeholder: 'email',
          formBuild: {
            default: "",
            validators: [Validators.required, Validators.email]
          }
        },
        {
          classFormGroup: 'col-12',
          classInput: '',
          key: 'captcha',
          type: 'captcha',
          formBuild: {
            default: '',
            validators: [Validators.required]
          }
        }
      ]
    }
  ]

  constructor(private authService: AuthService, private translateService: TranslateService) { }

  ngOnInit(): void {
  }

  recovery($event) {
    this.msg = '';
    this.authService.recovery($event).subscribe(isOk => {
      this.msg = this.translateService.instant('recovery_email_sended')
    },
      isKo => {
        console.log(isKo);
        this.msg = '';
      });
  }

}
