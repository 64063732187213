import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})


export class PaypalService {

  client_id = environment.paypal_client_id_partner;
  secret = environment.paypal_secret_partner;

  basePaypal = 'https://api-m.sandbox.paypal.com';

  constructor(private http: HttpClient) {}

  getToken() {
    let key = this.client_id + ':' + this.secret;

    let headers = new HttpHeaders({
      "Accept": "application/json",
      "Accept-Language": "en_US",
      'Authorization': 'Basic ' + btoa(key),
      'Content-Type': 'application/x-www-form-urlencoded',
    });

    let url = `${this.basePaypal}/v1/oauth2/token`;
    return this.http.post<any>(url, "grant_type=client_credentials", { headers: headers });
  }

  getUserToken(code) {
    let key = environment.paypal_client_id + ':' + environment.paypal_secret;

    let headers = new HttpHeaders({
      "Accept": "application/json",
      "Accept-Language": "en_US",
      'Authorization': 'Basic ' + btoa(key),
      'Content-Type': 'application/x-www-form-urlencoded',
    });

    let url = `${this.basePaypal}/v1/oauth2/token`;
    return this.http.post<any>(url, `grant_type=authorization_code&code=${code}`, { headers: headers });
  }

  getUserInfo(access_token){
    let headers = new HttpHeaders({
      "Accept": "application/json",
      "Accept-Language": "en_US",
      'Authorization': `Bearer ${access_token}`,
      "Content-Type": "application/json",
    });

    let url = `${this.basePaypal}/v1/identity/oauth2/userinfo?schema=paypalv1.1`;
    return this.http.get<any>(url,{ headers: headers });
  }

  getReferrals(access_token: string): any {
    let body = {
      "tracking_id": "<Tracking-ID>",
      "operations": [
        {
          "operation": "API_INTEGRATION",
          "api_integration_preference": {
            "rest_api_integration": {
              "integration_method": "PAYPAL",
              "integration_type": "THIRD_PARTY",
              "third_party_details": {
                "features": [
                  "PAYMENT",
                  "REFUND",
                  "PARTNER_FEE"
                ]
              }
            }
          }
        }
      ],
      "products": [
        "EXPRESS_CHECKOUT"
      ],
      "legal_consents": [
        {
          "type": "SHARE_DATA_CONSENT",
          "granted": true
        }
      ],
      "partner_config_override": {
        "partner_logo_url": `${environment.return_url}/assets/img/logo.png`,
        "return_url": `${environment.return_url}/my-panel/settings`,
        "return_url_description": "the url to return the merchant after the paypal onboarding process.",
        "action_renewal_url": `${environment.return_url}/product/61e3f1ac462b83078ee7ff86`,
        "show_add_credit_card": true
      }
    }

    const headers = new HttpHeaders({
      'Accept': 'application/json',
      'Accept-Language': 'en_US',
      'Authorization': `Bearer ${access_token}`,
      'Content-Type': 'application/json',
    });


    let url = `${this.basePaypal}/v2/customer/partner-referrals`;
    return this.http.post<any>(url, body, { headers: headers }).subscribe(result => {
      let urlLogin = result.links[1].href;
      window.open(urlLogin, '_blank')
    });
  }


  addPaypalScript() {
    return new Promise((resolve) => {
      let scripttagElement = document.createElement('script');
      scripttagElement.src = 'https://www.paypalobjects.com/js/external/api.js';
      scripttagElement.onload = resolve;
      document.body.appendChild(scripttagElement);
    })
  }

  addPaypalPaymentScript(merchantId: string = '') {
    return new Promise((resolve, reject) => {
      let scripttagElement = document.createElement('script');
      scripttagElement.src = `https://www.paypal.com/sdk/js?&client-id=${this.client_id}&merchant-id=${merchantId}&currency=EUR`;
      scripttagElement.onload = resolve;
      document.body.appendChild(scripttagElement);
    })
  }
}
