<div class="row mbt-5">
  <div class="col-12 mb-3">
    <h3>
      {{'payments.payment_method' | translate}}
    </h3>
  </div>
  <div class="col-12 col-md-12">
    <div class="row box">
      <label>
        <h3 class="page-subheading">{{'payments.select_payment' | translate}}</h3>
      </label>
      <div class="col-12">
        <div class="btn-payment-container" *ngIf="paymentsDict['transfer']?.status">
          <button (click)="openConfirm(confirm, paymentsDict['transfer']._id)" class="button button-small btn btn-primary payment-btn"
            type="radio" name="payment" value="bank_transfer">
            <fa-icon [icon]="bank"></fa-icon> {{'payments.bank_transfer' | translate}}
          </button>
        </div>
        <div class="btn-payment-container" *ngIf="paymentsDict['bitcoin']?.status">
          <button (click)="openConfirm(confirm, paymentsDict['bitcoin']._id)" class="button button-small btn btn-primary payment-btn"
            type="radio" name="payment" value="bank_transfer">
            <fa-icon [icon]="bitcoun"></fa-icon> Bitcoin
          </button>
        </div>
        <div class="btn-payment-container" *ngIf="paymentsDict['paypal']?.status">

          <button (click)="openConfirm(confirm, paymentsDict['paypal']._id)" class="button button-small btn btn-primary payment-btn"
            type="radio" name="payment" value="PayPal">
            <fa-icon [icon]="paypal"></fa-icon> PayPal
          </button>
        </div>
        <!--div class="btn-payment-container">
          <div class="paypal-button-container" id="paypal-button-container"></div>
        </div-->
      </div>

    </div>
  </div>

  
<ng-template #confirm let-modal>
  <div class="modal-header">
      <h4 class="modal-title" id="modal-title">{{'finish_order' | translate}}</h4>
      <button type="button" class="close" aria-describedby="modal-title" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
      </button>
  </div>
  <div class="modal-body">
      <p><strong>{{'finish_order_msg' | translate}} </strong></p>
  </div>
  <div class="modal-footer">
      <button type="button" class="btn btn-danger" (click)="modal.dismiss('cancel click')">{{'cancel' |
          translate}}</button>
      <button type="button" class="btn btn-primary" (click)="modal.dismiss(); pay() ">{{'accept' | translate}}</button>
  </div>
</ng-template>