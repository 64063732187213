import { Component, OnInit, Output, EventEmitter, Input, ElementRef, ViewChild, AfterViewChecked } from '@angular/core';
import { faPaperPlane } from '@fortawesome/free-regular-svg-icons';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-messagepanel',
  templateUrl: './messagepanel.component.html',
  styleUrls: ['./messagepanel.component.scss']
})
export class MessagepanelComponent implements OnInit, AfterViewChecked {
  @ViewChild('scrollMe') private myScrollContainer: ElementRef;
  send = faPaperPlane;
  back = faChevronLeft;
  msg = "";
  @Input() msgs = [];
  @Input() id = '';
  @Input() name = '';
  @Input() support = false;
  @Input() iAm: string;
  @Output() sendEmit = new EventEmitter<string>();
  @Input() showContacts: boolean;
  @Output() showContactsChange = new EventEmitter<boolean>();
  @Input() isMobile = false;

  constructor(public translate:TranslateService) { }
  ngAfterViewChecked(): void {
    try {
      this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
    } catch (err) { }
  }

  ngOnInit(): void { }

  sendMsg() {
    if (!this.msg) return;
    this.sendEmit.emit(this.msg);
    this.msg = "";
  }

}
