<div class="row">
  <div class="col-12">
    <div class="row box">
      <div class="col-12 col-md-2 text-center pic-cont">
        <div class="pic" [style.background-image]="'url(' + product?.imgs[0]  + ')'">
        </div>
      </div>
      <div class="col-12 col-md-7 mt-2">
        <h5>
          <a [routerLink]="['/product', product?._id]">{{product?.name}}</a>
        </h5>
        <h5>
          <small>
            <a class="text-muted font-weight-bold" [routerLink]="['/store/on-sale/', product?.store?._id]">{{product?.store?.name}}</a>
          </small>
        </h5>
      </div>
      <div class="col-12 col-md-2 price mt-2">
        <h5>
          {{productPrice}}{{product?.store?.currency | CurrencyPipe}}
        </h5>
      </div>
    </div>
  </div>
</div>

<div class="row less">
  <div class="col-12 col-md-4 offset-md-8">
    <div class="row box">
      <div class="col-6 font-weight-bold">
        Shipping
      </div>
      <div class="col-6 font-weight-bold">
        {{deliveryPrice}}{{product?.store?.currency | CurrencyPipe}}
      </div>
      <hr>
      <div class="col-6 font-weight-bold">
        Total
      </div>
      <div class="col-6 p-2">
        {{totalPrice}}{{product?.store?.currency | CurrencyPipe}}
      </div>
    </div>
  </div>
</div>