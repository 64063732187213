<div class="container">
  <div class="mt-3 mb-4 text-center rate-global" (mouseleave)="leave()">
    <div class="rate-container" (click) ="click(r)" *ngFor="let r of [1,2,3,4,5]" (mouseover) = "hover(r)" [ngClass]="{'active': rate >= r}">
      <fa-icon [icon]="star" class="rate"></fa-icon>
    </div>
  </div>
  <div class="text-center">
    <div class="text-center" *ngIf="isStatic">
      ({{numberRates}})
    </div>
  </div>
</div>