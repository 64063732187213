<div class="container minH">
    <div class="row">
        <div class="col-12 text-center mt-5 ">
            <h1>{{'login' | translate}}</h1>
            <img src="./assets/img/logo.png">
        </div>
    </div>

    <div class="row">
        <div class="col-12 col-md-8 offset-md-2 pt-5 text-center">
            <app-dynamicform [buttons] = "buttonsData" [form] = "formValue"></app-dynamicform>
        </div>
        <div class="col-12 col-md-8 offset-md-2 mb-4 texto">
            <a [routerLink] = "'../recovery'"><i>{{'forgotten_pass' | translate}}</i></a>
        </div>
        <div class="col-12 text-center mt-4 mb-5">
            <p>{{'no_account' | translate}} <a [routerLink] = "'../register'">{{'sign_up' | translate}}</a></p>
        </div>
    </div>
</div>