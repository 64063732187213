<div class="skelleton-content">
  <div class="row">
    <div class="col-12">
      <ngx-skeleton-loader count="1" appearance="circle" [theme]="{
        width: '200px',
        height: '220px',
        'border-radius': '10px'
      }">
      </ngx-skeleton-loader>
    </div>
  </div>

  <div class="row">
    <div class="col-8">
      <ngx-skeleton-loader count="1" animation="pulse" [theme]="{height: '20px', width:'140px'}">
      </ngx-skeleton-loader>
    </div>
    <div class="col-4">
      <ngx-skeleton-loader count="1" animation="pulse" [theme]="{height: '20px', width:'40px'}"></ngx-skeleton-loader>
    </div>
  </div>
  <div class="row">
    <div class="col-4">
      <ngx-skeleton-loader count="1" appearance="circle" [theme]="{
        marginLeft:'15px',
              width: '45px',
              height: '45px',
              'border-radius': '50px'
            }"> </ngx-skeleton-loader>
    </div>
    <div class="col-8">
      <ngx-skeleton-loader count="1" animation="pulse" [theme]="{height: '20px', width:'120px', marginTop:'15px'}">
      </ngx-skeleton-loader>
    </div>

  </div>
</div>
