import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TokenService } from '../token.service';
import { ToastService } from './toast.service';

@Injectable({
  providedIn: 'root'
})

export class ErrorService {


  constructor(private toast: ToastService, private tokenservice: TokenService, private translateService: TranslateService) { }

  error(error: any) {
    this.toast.error(this.translateService.instant("error." + error.error.message));
    if (error.error.message === 'TOKEN_NOT_VALID' || error.error.message === 'NO_TOKEN' || error.error.message === 'USER_NOT_FOUND') {
      this.tokenservice.logout();
    }

  }

  msg(msg: string) {
    this.toast.error(msg);
  }

  form() {
    this.toast.error("Must Complete Data to Continue");
  }


  getLabelError(keyError) {
    if ('required' == keyError) {
      return 'Necesario'
    }
    if ('minlength' == keyError) {
      return 'Demasiado Pequeño'
    }


    return 'Erroneo'
  }

}
