<div #header_par [ngClass]="{'no-parallax': !haveParalax}" class="parallaxh" id="header_par"
  [style.background-image]="'url(' + data.backgroundimg  + ')'">

  <div class="container-fluid align-bottom">
    <div class="row p-3">
      <div class="text-center content">
        <div class="avatar" [style.background-image]="data.img ? 'url(' + data.img  + ')' : 'url('+('orange.png' | assetsRoute:'img')+')' "></div>
        <h3 class="store mt-4 pt-2">{{data?.title}}</h3>
      </div>
      <div class="col-12 col-md-7 text mt-4" *ngIf="data.isMine">
        <a class="btn btn-light btn-lg" [routerLink]="['settings']">Editar</a>
      </div>
    </div>
  </div>
</div>