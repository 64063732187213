import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { faCircle, faStar } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-rate',
  templateUrl: './rate.component.html',
  styleUrls: ['./rate.component.scss']
})
export class RateComponent implements OnInit {

  circle = faCircle;
  star = faStar;
  selected = 0;
  @Input() rate = 0;
  @Input() numberRates = 0;
  @Input() isStatic = true;
  @Output() rateEmit = new EventEmitter();

  constructor() { 
  }

  ngOnInit(): void {
    this.selected = this.rate;
  }

  hover(r) {
    if (this.isStatic) return;
    this.rate = r
  }
  leave() {
    if (this.isStatic) return;
    this.rate = this.selected
  }

  click(n: number) {
    if (this.isStatic) return;
    this.selected = n;
    this.rateEmit.emit(this.selected);
  }

}
