<div class="row p-5 text-center" (window:resize)="onResize($event)">
    <ng-container *ngFor="let cat of categories; let i = index">
        <div class="col-6 col-md-2 text-center" *ngIf="i <= limitItems-1">
            <a (click)="goToCategory(cat.slug)">
                <div class="avatar mb-2"
                    [style.background-image]="cat?.img ? 'url(' +cat.img + ')' : 'url('+('orange.png' | assetsRoute:'img')+')' ">
                </div>
                <label class="mb-3">{{ cat.label }}</label>
            </a>
        </div>
    </ng-container>
</div>