import { Injectable } from "@angular/core";
import { BaseService } from "./common/base.service";
import { LoadingService } from './loading.service';
import { HttpClient } from "@angular/common/http";
import { ErrorService } from "./common/error.service";
import { TokenService } from './token.service';
import { ToastService } from './common/toast.service';

@Injectable({
  providedIn: 'root'
})
export class ChatService extends BaseService {

  constructor(public http: HttpClient, public error: ErrorService, public token: TokenService, public toast:ToastService, public loadingService:LoadingService) {
    super(http, error, token, toast, loadingService);
    this.host = this.host + "chat";
  }

  getIfUser = () => {
    let url = this.host + '/user';

    return this.doGet(url);
  }

  getIfStore = () => {
    let url = this.host + '/store';

    return this.doGet(url);
  }

  sendMessage = (body: any) => {
    return this.doPost(this.host, body);
  }

  getChatDetails = (id: string) => {
    let url = this.host + '/details/' + id;

    return this.doGet(url);
  }


}

