<app-menu></app-menu>
<div class="router-container">
	<div *ngIf="loading" class="loading row h-100">
		<div class="col-sm-12  my-auto">
			<div class="img-container">
				<img src="{{'orange.png' | assetsRoute:'img'}}" class="img-fluid" alt="Responsive logo">
			</div>
			<div class="spinner-border text-primary" role="status">
				<span class="sr-only">Loading...</span>
			</div>
		</div>
	</div>

	<div class="cookies-policy" *ngIf="showCookies">
		<p>
			{{'cookies-consent' | translate}}
		</p>
		<button (click)="acept()" class="btn btn-primary">{{'Accept' | translate}}</button>
	</div>

	<router-outlet></router-outlet>
</div>
<app-footer></app-footer>