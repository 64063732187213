<div class="container" *ngIf="isDragList">
	<div class="row" *ngIf="data">
		<div class="col-12">
			<ng-container *ngIf="data.length > 0">
				<drag-scroll #nav>
					<div drag-scroll-item class="cards card-container cards-drawable" *ngFor="let store of data">
						<a [routerLink]="['/store', store._id]">
							<div class="tendero mb-1">
								<div class="square1R"></div>
								<div class="square2"></div>
								<div class="square1"></div>
								<div class="square2"></div>
								<div class="square1"></div>
								<div class="square2R"></div>
							</div>
							<div class="tendero">
								<div class="shape1"></div>
								<div class="shape2"></div>
								<div class="shape1"></div>
								<div class="shape2"></div>
								<div class="shape1"></div>
								<div class="shape2"></div>
							</div>
							<div class="pic"
								[style.background-image]="store.img ? 'url(' +store.img + ')' : 'url('+('logoText.png' | assetsRoute:'img')+')' ">
							</div>
							<div class="contain">
								<div class="row">
									<div class="col-12 text-center">
										<h5 class="posicion"><b>{{store?.name}}</b></h5>
									</div>
								</div>
							</div>
						</a>
					</div>
					<div (click)="moreEmit.emit()" class="see_more">
						{{'view_more' | translate}}
					</div>
					<div class="movebtn prev-btn" (click)="moveLeft()">
						<fa-icon [icon]="left" size="3x"></fa-icon>
					</div>
					<div class="movebtn next-btn" (click)="moveRight()">
						<fa-icon [icon]="right" size="3x"></fa-icon>
					</div>
				</drag-scroll>
			</ng-container>

			<ng-container *ngIf="data.length === 0">
				<h5>
          {{'no_stores' | translate}}
        </h5>
			</ng-container>
			
		</div>
	</div>
</div>

<div class="row" *ngIf="!isDragList">
	<div class="col-12">
		<ngb-pagination (pageChange)="changepageEmit.emit($event)" [(page)]="page" [pageSize]="pageSize"
			[collectionSize]="collectionSize" [boundaryLinks]="true" [maxSize]="8"></ngb-pagination>
	</div>

	<div class="cards card-container" *ngFor="let store of data">
		<a [routerLink]="['/store', store._id]">
			<div class="pic"
				[style.background-image]="store.img ? 'url(' +store.img + ')' : 'url('+('logoText.png' | assetsRoute:'img')+')' ">
			</div>
			<div class="contain">
				<div class="row">
					<div class="col-12 text-center">
						<h5 class="posicion"><b>{{store?.name}}</b></h5>
					</div>
				</div>
			</div>
		</a>
	</div>

	<div class="col-12">
		<br />
		<ngb-pagination (pageChange)="changepageEmit.emit($event)" [(page)]="page" [pageSize]="pageSize"
			[collectionSize]="collectionSize" [boundaryLinks]="true" [maxSize]="8"></ngb-pagination>
	</div>
</div>