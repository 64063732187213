import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Buy } from '../models/buy.model';
import { Product } from '../models/product.model';
import { CartService } from '../services/cart.service';
import { ProductsService } from '../services/products.service';
import { CheckoutPaymentComponent } from './components/checkout-payment/checkout-payment.component';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit {

  @ViewChild('payments') payments: CheckoutPaymentComponent;
  product: Product = null;
  addressId = '';
  id = '';
  observations = '';
  selectedPayment = '';
  
  constructor(private activatedRoute: ActivatedRoute,private router:Router, private productsService: ProductsService, private cartService: CartService) {
    this.id = this.activatedRoute.snapshot.params['id'];
    this.productsService.getProduct(this.id).subscribe((product: Product) => {
      this.product = product;
      this.payments.getStorePayments(product.store._id);
    });
  }

  ngOnInit(): void {}

  send(paymentId:string) {
   
    const buy:Buy = {
      address: this.addressId,
      payMethod: paymentId,
      products: [{ product: this.id }],
      observations: this.observations
    }

    this.cartService.checkout(buy).subscribe(_v => {
      this.router.navigate(['/account/purchases'])
    })
  }



}
