import { Injectable } from "@angular/core";

import { HttpClient, HttpHeaders, HttpParams, HttpRequest } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Observable } from "rxjs";
import { ErrorService } from './error.service';
import { TokenService } from '../token.service';
import { ToastService } from './toast.service';
import { LoadingService } from '../loading.service';

@Injectable({
  providedIn: "root"
})
export class BaseService {
  host = environment.host + '/';
  public options: any;

  constructor(public http: HttpClient, public error: ErrorService,
    public tokenService: TokenService, public toast: ToastService,
    public loadingService: LoadingService) {
  }

  public setOptions = (params: any = null) => {
    let token = this.tokenService.get();
    let headers = new HttpHeaders();
    headers.set("Authorization", '0');
    if (token) {
      headers = new HttpHeaders()
        .set("Authorization", token);
    }

    let httpParams = new HttpParams();

    if (params) {
      Object.keys(params).forEach((key) => {
        httpParams = httpParams.set(key, params[key]);
      });
    }

    this.options = { headers: headers, observe: 'response' , params : params};
    return this.options;
  }

  public getFormData = (item) => {

    const keys = Object.keys(item);
    const formData = new FormData();

    keys.forEach(function (key) {
      let value = item[key];
      if (!value) {
        return;
      }
      if (!Array.isArray(value)) {
        let type = Object.prototype.toString.call(value) === '[object Date]'
        if (typeof value === 'object' && (!type)) {
          if (value instanceof File) {
            formData.append(key, value);
          } else {
            formData.append(key, value['_id']);
          }
        } else {
          formData.append(key, value);
        }
      } else {
        value.forEach(function (sKey) {
          if (typeof sKey === 'object') {
            formData.append(key + '[]', sKey['_id'] || sKey['id']);
          } else {
            formData.append(key + '[]', sKey);
          }
        });
      }
    });

    return formData;
  }

  renewToken(data) {
    if (!data) return
    const token = data.headers.get('x-access_token')
    if (!token || token == '-1') return
    this.tokenService.save(token)
  }

  public doPost = (url: string, body: any, showLoading = true, toastOk: string = 'OK') => {

    let opts = this.setOptions();
    this.loadingService.setLoading(showLoading);
    const observer = new Observable(observer => {
      this.http.post(url, body, opts).subscribe(
        data => {
          this.renewToken(data)
          this.loadingService.setLoading(false);
          const resData = data["body"].data
          if (this.toast && showLoading) {
            this.toast.ok(toastOk);
          }
          observer.next(resData);
          observer.complete();
        },
        err => {
          this.loadingService.setLoading(false);
          console.log("ERROR", err);
          this.error.error(err);
          observer.error(err);
          observer.complete();
        }
      );
    });
    return observer;
  }

  public doPut = (url: string, body: any, showLoading = true) => {
    let opts = this.setOptions();
    this.loadingService.setLoading(showLoading);
    const observer = new Observable(observer => {
      this.http.put(url, body, opts).subscribe(
        data => {
          this.renewToken(data)
          this.loadingService.setLoading(false);
          const resData = data["body"].data
          if (this.toast) {
            this.toast.ok('OK');
          }
          observer.next(resData);
          observer.complete();
        },
        err => {
          this.loadingService.setLoading(false);
          console.log("ERROR", err);
          this.error.error(err);
          observer.error(err);
          observer.complete();
        }
      );
    });
    return observer;
  }

  public doGet = (url: string, showLoading = true, opts = this.setOptions()) => {
    this.loadingService.setLoading(showLoading);
    const observer = new Observable(observer => {
      this.http.get(url, opts).subscribe(
        (data: any) => {
          this.loadingService.setLoading(false);
          const resData = data["body"].data;
          observer.next(resData);
          observer.complete();
        },
        err => {
          this.loadingService.setLoading(false);
          console.log("ERROR", err);
          this.error.error(err);
          observer.error(err);
          observer.complete();
        }
      );
    });
    return observer;
  }

  public doDelete = (url: string, showLoading = true, toastOk: string = 'OK') => {
    this.loadingService.setLoading(showLoading);
    let opts = this.setOptions();
    if (this.toast && showLoading) {
      this.toast.info('Waiting...');
    }
    const observer = new Observable(observer => {

      this.http.delete(url, opts).subscribe(
        data => {
          this.renewToken(data)
          this.loadingService.setLoading(false);
          const resData = data["body"].data
          if (this.toast && showLoading) {
            this.toast.ok(toastOk);
          }
          observer.next(resData);
          observer.complete();
        },
        err => {
          this.loadingService.setLoading(false);
          console.log("ERROR", err);
          this.error.error(err);
          observer.error(err);
          observer.complete();
        }
      );
    });
    return observer;
  }

  public doPostFile = (url: string, body: any, showLoading = true) => {
    this.loadingService.setLoading(showLoading);
    const req = new HttpRequest('POST', url, body, {
      reportProgress: true,
      responseType: 'json',
    });

    return this.http.request(req);
  }

  public create = (item: any) => {
    this.setOptions();
    //let body = this.getFormData(item);
    return this.doPost(this.host, item);
  }

  public update = (item: any) => {
    this.setOptions();
    //let body = this.getFormData(item);
    return this.doPost(this.host + "/" + item._id, item);
  }

  public list = (params = null) => {
    return this.doGet(this.host, true, this.setOptions(params));
  }
  public delete = (id) => {
    this.setOptions();
    let url = this.host + "/" + id;
    return this.doDelete(url);
  }

  public get = (id) => {
    this.setOptions();
    let url = this.host + "/" + id;
    return this.doGet(url);
  }

  public put = (item:any) => {
    this.setOptions();
    let url = this.host + "/" + item._id;
    return this.doPut(url, item);
  }

  public putResponse = (item:any) => {
    this.setOptions();
    let url = this.host + "/response/" + item._id;
    return this.doPut(url, item);
    //return this.doGet('https://ips6xzawvj.execute-api.eu-west-2.amazonaws.com/')
  }
}
