import { Component, OnInit, Input, OnChanges, HostListener } from '@angular/core';
import { ChatService } from 'src/app/services/chat.service';
import { ToastService } from 'src/app/services/common/toast.service';
import { NotificationEventCodes } from 'src/app/services/models/notificatonData';
import { PushNotificationService } from 'src/app/services/notifications/pushnotifications';


@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit, OnChanges {

  @Input() data: any[];
  @Input() iAm: string;
  @Input() notificationList = {};

  contacts: any[] = [];
  messages: any[] = [];
  selectedContact: any;
  contactsType: string;
  isMobile = false;
  showContacts = true;

  @HostListener('window:resize', ['$event'])
  onResize(_v) {
    this.checkResolution();
  }

  constructor(private chatService: ChatService, private pushNotifications: PushNotificationService, private toastService: ToastService) { }

  ngOnInit(): void {
    this.checkResolution();
  }

  ngOnChanges(): void {
    this.getContacts();
  }

  checkResolution() {
    let screenWidth = window.innerWidth;

    if (screenWidth > 760) {
      this.isMobile = false
      this.showContacts = true;
    }
    else {
      this.isMobile = true;
    }
  }

  getContacts() {
    this.contacts = this.data.map(elem => {
      let ni;

      if (typeof elem.store === 'object') {
        ni = { ...elem.store };
        this.contactsType = 'store';
      } else {
        ni = { ...elem.user };
        this.contactsType = 'user';
      }

      ni['last'] = elem.last;
      ni['chatId'] = elem._id;
      return ni;
    });


    if (this.contacts.length) {
      this.selectedContact = this.selectedContact ? this.selectedContact : this.contacts[0];
      if(this.isMobile) return;
      this.getConversation(this.selectedContact.chatId);
    }
  }

  getConversation = (id: any) => {
    if(this.isMobile){
      this.showContacts = false;
    }
    let isString = typeof id === 'string'
    let contact = this.contacts.find(elem => elem.chatId == id);
    if (contact && id && isString) {
      this.selectedContact = contact;
      this.chatService.getChatDetails(id).subscribe((data: any) => {
        this.messages = data;
        let type = this.iAm === 'store' ? NotificationEventCodes.chatStore : NotificationEventCodes.chatUser;
        this.pushNotifications.checkViewed(id, type);
      });
    } else {
      this.messages = []
      this.selectedContact = this.contacts.find(elem => elem._id + '' === id.id + '');
      //this.selectedContact = this.contacts.find(elem => elem._id == id._id);
    }

  }

  writeMessage = (msg: string) => {

    let body = {
      msg: msg
    }

    if (this.contactsType == 'store') {
      body['store'] = this.selectedContact._id;
    } else {
      body['user'] = this.selectedContact._id;
    }

    this.chatService.sendMessage(body).subscribe((data: any) => {
      if (!this.selectedContact.chatId) {
        this.selectedContact.chatId = data._id;
        let idx = this.contacts.findIndex(elem => elem._id == this.selectedContact._id);

        if (idx > -1) this.contacts[idx].chatId = data._id;
      }
      setTimeout(() => { this.getConversation(this.selectedContact.chatId) }, 500);

    });


  }

}
