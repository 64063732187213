import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'assetsRoute'
})
export class AssetsRoutePipe implements PipeTransform {

  transform(value: unknown, args: string): string {
    let val = args ? args+"/"+value : value ;
    return '/assets/'+val;
  }

}
