<div class="container-fluid pt-5">
  <div class="row">

    <div class="col-12 col-md-3 d-none d-md-block">
      <h4>{{category}}</h4>
      <menu-categories [categories]="categories" [level]='0' (clickCategory)="clickItem($event)"></menu-categories>
    </div>
    <div class="col-12 col-md-9 text-center mt-5 mb-5">
      <div class="min">
        <app-cardlist (changepageEmit)="getProducts($event)" [data]="products" [page]="page" [pageSize]="pageSize"
          [collectionSize]="collectionSize">
        </app-cardlist>
      </div>
    </div>
  </div>