import { Component, Input, Output, EventEmitter } from '@angular/core';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgxPicaErrorInterface, NgxPicaResizeOptionsInterface, NgxPicaService } from '@digitalascetic/ngx-pica';

interface ImageSnippet {
  src: string,
  file: File
}
const options: NgxPicaResizeOptionsInterface = {
  exifOptions: {
    forceExifOrientation: true
  },
  aspectRatio: {
    keepAspectRatio: true,
  },
};

@Component({
  selector: 'app-uploadimages',
  templateUrl: './uploadimages.component.html',
  styleUrls: ['./uploadimages.component.scss']
})
export class UploadimagesComponent {

  public selectedFile: ImageSnippet;
  public faTimes = faTimes;
  public loading = false;
  @Input() isEdit = true;
  @Input() data = '';
  @Output() uploadEmit = new EventEmitter<File>();

  @Output() isEditChange = new EventEmitter<boolean>()

  constructor(private _ngxPicaService: NgxPicaService) {

  }

  processFile(imageInput: any) {
    const file: File = imageInput.files[0];
    const reader = new FileReader();

    reader.addEventListener('load', (event: any) => {
      this.selectedFile = { src: event.target.result, file: file };
    });

    reader.readAsDataURL(file);
  }

  resizeFile(file: File) {
    this.loading = true;
    this._ngxPicaService.compressImage(file, 1, options).subscribe(
      (imageResized: File) => {
        this.loading = false;
        this.uploadEmit.emit(imageResized);
        const reader: FileReader = new FileReader();

        reader.addEventListener(
          'load',
          (event: any) => {
            //this.dataPreview = event.target.result;
            this.selectedFile = { src: event.target.result, file: imageResized };
          },
          false
        );

        reader.readAsDataURL(imageResized);
      },
      (err: NgxPicaErrorInterface) => {
        this.loading = false;
        throw err.err;
      }
    );
  }

  deleteImage() {
    this.data = '';
    this.selectedFile = null;
    this.uploadEmit.emit(null);
  }

}
