<ul class="list-group">
  <ng-container
    *ngTemplateOutlet="recursiveListTmpl; context:{ list: list }"
  ></ng-container>
</ul>

<ng-template #recursiveListTmpl let-list="list">
  <li class="list-group-item" (click)="goToCategory(item, $event)" *ngFor="let item of list">
    <div class="category"> {{ item.label }}</div>
   
  </li>
</ng-template>