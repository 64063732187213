import { Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { AuthService } from '../services/auth.service';
import { DynamicFormModel } from '../shared/components/dynamicform/models/dynamicform.model';
import { Router } from '@angular/router';
import { TokenService } from '../services/token.service';
import { TranslateService } from '@ngx-translate/core';
import { PushNotificationService } from '../services/notifications/pushnotifications';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  user: string = '';

  pHolderPass: string;

  public buttonsData: any = {
    parentClass: 'mt-3 mb-3',
    buttons: [
      {
        cssContainer: "",
        cssInput: "btn btn-primary text-white",
        title: "login",
        callback: (values) => {
          this.login(values);
        }
      },
    ]
  }


  public formValue: DynamicFormModel[] = [
    {
      parentClass: 'row',
      inputs: [
        {
          classFormGroup: 'col-12',
          classInput: '',
          key: 'email',
          type: 'email',
          placeholder: 'email',
          formBuild: {
            default: "",
            validators: [Validators.required]
          }
        },
        {
          classFormGroup: 'col-12',
          classInput: '',
          key: 'password',
          type: 'password',
          placeholder: this.translateService.instant('placeholder.password'),
          formBuild: {
            default: "",
            validators: [Validators.required]
          }
        },
      ]
    }
  ]


  constructor(private authService: AuthService,
    private router: Router,
    private pushNotificationService: PushNotificationService,
    private translateService: TranslateService) {
    //this.user = localStorage.getItem('user');
  }

  ngOnInit(): void {

  }

  login($event) {
    this.authService.login($event).subscribe(isOk => {
      if (isOk) {
        this.pushNotificationService.requestPermission().then(deviceToken => {
          this.authService.saveDeviceToken(deviceToken).subscribe(data => {
            this.pushNotificationService.listen();
            this.pushNotificationService.onMessage();
            this.pushNotificationService.getNotificationList()
          })
        })
        this.router.navigate(['/home']);
      }
    },
      isKo => {
        console.log(isKo);
      })
  }

  getUserName = () => {
    let ui = (<HTMLInputElement>document.getElementById('email'));

    if (ui && this.user) ui.value = this.user;
  }



}
