<div #header_par class="parallaxh" id="header_par" [style.background-image]="img ? 'url(' +img + ')' : 'url('+('logo.png' | assetsRoute:'img')+')' ">
	<div class="container-fluid align-bottom">
		<div class="content">
			<div class="row item">
				<div class="col-11 col-md-12 ">
					<h1 class="font-weight-bold text-white mb-2 mt-5">{{title}}</h1>
				</div>
				<div class="col-6 col-md-12 ">
					<h2 class="text-uppercase text-white">{{subtitle}}</h2>
				</div>
			</div>
		</div>
	</div>
</div>

<div class="container-fluid">
	<app-categorymenu [categories]="categories"></app-categorymenu>
</div>
<div class="container-fluid">
	<div class="row">
		<div class="col-12 text-center mt-3">
			<h2 class="pegado">{{'news' | translate}}</h2>
		</div>
	
	</div>
	<!--div class="nail"></div-->
	<div class="row back mbm">
		<div class="col-12 text-center pt-4">
			<app-cardlist (moreEmit)="moreNew()" [isDragList]="true" [data]="newProducts"></app-cardlist>
		</div>

	</div>


	<div class="row">
		<div class="col-12 text-center mt-3">
			<h2 class="pegado">{{'most_viewed' | translate}}</h2>
		</div>
	</div>

	<div class="row back mbm">
		<div class="col-12 text-center pt-4">

			<app-cardlist (moreEmit)="moreMost()" [isDragList]="true" [data]="mostViewedProducts"></app-cardlist>


		</div>
	</div>

	<div class="row">
		<div class="col-12 text-center mt-3">
			<h2 class="pegado">{{'new_sellers' | translate}}</h2>
		</div>
	</div>

	<div class="row back mbm">
		<div class="col-12 text-center pt-4">
			<app-store-list (moreEmit)="moreStores()" [isDragList]="true" [data]="newStores"></app-store-list>
		</div>

	</div>
</div>